import React from 'react';

import classes from './SectionBlogCard.module.scss';
import { HashLink as Link } from 'react-router-hash-link';

const CardBlog = (props) => {
    return (
        <a href={props.anchor}>
        <div className={classes.card}>
            
            <div className={classes.media}>
                <img src={props.image} alt={props.altText}/>
            </div>
            <div className={classes.text}>
                <span className={classes.titulo}><p>{props.title}</p></span>
                <p>{props.description}</p>
            </div>
        </div>
         </a>
    );
}

export default CardBlog;
