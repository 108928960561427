import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ScrollToTop from './components/util/ScrollToTop';

import Layout from './pages/Layout';
import HomePage from './pages/HomePage';
import SimuladorPerda from './pages/SimuladorPerda';
import Valid from './pages/ValidPage';
import Rota from './pages/RotaPage';
import Blog from './pages/blog/Blog';
import Postagens from './pages/blog/Postagens';
import BlogDetalhe from './pages/blog/BlogDetalhe';
import ListaUsuarios from './pages/admin/usuarios/ListaUsuarios.jsx';
import NovoUsuario from './pages/admin/usuarios/NovoUsuario.jsx';
import ListaPostagem from './pages/admin/blog/ListaPostagem.jsx';
import NovaPostagem from './pages/admin/blog/NovaPostagem.jsx';
import EdicaoPostagem from './pages/admin/blog/EdicaoPostagem.jsx';
import Login from './pages/admin/Login.jsx';
import ProtectedRoute from './pages/admin/ProtectedRoute.jsx';
import Profile from './pages/admin/Profile';
import EdicaoSenhaUsuario from './pages/admin/usuarios/EdicaoSenhaUsuario';
import EdicaoPermissoes from './pages/admin/usuarios/EdicaoPermissoes';
import ListaPropagandas from './pages/admin/propagandas/ListaPropagandas';
import NovaPropaganda from './pages/admin/propagandas/NovaPropaganda';
import EdicaoPropaganda from './pages/admin/propagandas/EdicaoPropaganda';
import ListaVideos from './pages/admin/videos/ListaVideos';
import NovoVideo from './pages/admin/videos/NovoVideo';
import VideoDetalhado from './pages/blog/VideoDetalhado';
import EdicaoVideo from './pages/admin/videos/EdicaoVideo';
import NotFound from './pages/NotFound';
import Privacidade from './pages/Privacidade';
import Videos from './pages/blog/Videos';
import ListaTags from './pages/admin/tags/ListaTags';
import NovaTag from './pages/admin/tags/NovaTag';
import EdicaoTags from './pages/admin/tags/EdicaoTags';
import Servicos from './components/layout/Servicos';
import Sobre from './pages/SobrePage.js';

function App() {
    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path="simulador" element={<SimuladorPerda />} />
                    <Route path="valid" element={<Valid />} />
                    <Route path="rota" element={<Rota />} />
                    <Route path="sobre" element={<Sobre />} />

                    <Route path="sistemas" element={<Servicos />} />

                    <Route path="privacidade" element={<Privacidade />} />

                    <Route path="blog" element={<ScrollToTop />}>
                        <Route index element={<Blog />} />
                        <Route path=":postSlug" element={<BlogDetalhe />} />
                        <Route path="postagens" element={<Postagens />} />
                        <Route path="videos" element={<Videos />} />
                        <Route path="video/:videoSlug" element={<VideoDetalhado />} />
                    </Route>
                </Route>

                <Route path="/admin/login" element={<Login />} />

                <Route path="/admin" element={<ProtectedRoute />}>
                    <Route path="postagens">
                        <Route index element={<ListaPostagem />} />
                        <Route path="create" element={<NovaPostagem />} />
                        <Route path="edit" element={<EdicaoPostagem />} />
                    </Route>

                    <Route path="propagandas">
                        <Route index element={<ListaPropagandas />} />
                        <Route path="create" element={<NovaPropaganda />} />
                        <Route path="edit" element={<EdicaoPropaganda />} />
                    </Route>

                    <Route path="tags">
                        <Route index element={<ListaTags />} />
                        <Route path="create" element={<NovaTag />} />
                        <Route path="edit" element={<EdicaoTags />} />
                    </Route>

                    <Route path="videos">
                        <Route index element={<ListaVideos />} />
                        <Route path="create" element={<NovoVideo />} />
                        <Route path="edit" element={<EdicaoVideo />} />
                    </Route>

                    <Route path="usuarios">
                        <Route index element={<ListaUsuarios />} />
                        <Route path="create" element={<NovoUsuario />} />
                        <Route path="edit-senha" element={<EdicaoSenhaUsuario />} />
                        <Route path="edit-permissao" element={<EdicaoPermissoes />} />
                    </Route>

                    <Route path="profile" element={<Profile />} />
                </Route>

                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}
export default App;
