import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import classes from './Footer.module.scss';
import logoMinus from '../../../assets/images/minus.svg';

const Footer = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;
    const currentYear = new Date().getFullYear();

    if (!isMobilePage) {
        return (
            <div>
                <div className={classes.footer}>
                    <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }} s>
                        <img src={logoMinus} alt="Logo da Minus" />
                        <p className={classes.contato_privacidade}>
                            <Link to={'/privacidade'} className={classes.contato__info_privacidade}>
                                Políticas de Privacidade
                            </Link>
                        </p>
                    </div>
                    <p className={classes.texto}>{currentYear} © Todos direitos reservados</p>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <div className={classes.footer}>
                    <div>
                        <img src={logoMinus} alt="Logo da Minus" />
                    </div>
                    <p className={classes.texto}>{currentYear} © Todos direitos reservados</p>
                </div>
            </div>
        );
    }
};

export default Footer;
