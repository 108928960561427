import React from 'react';
import { Outlet } from 'react-router-dom';

import classes from '../App.module.scss';
import Header from '../components/layout/Header/Header';
import Contato from '../components/layout/Contato/Contato';
import Footer from '../components/layout/Footer/Footer';

const Layout = () => {
    return (
        <div className={classes.main}>
            <Header />

            <Outlet />

            <Contato />

            <Footer />
        </div>
    );
};

export default Layout;
