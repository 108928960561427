import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import plusIcon from '../../../assets/icons/add.svg';
import classes from '../Lista.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';
import Propaganda from './Propaganda';

const ListaPropagandas = () => {
    const [propagandas, setPropagandas] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const { token } = JSON.parse(sessionStorage.getItem('usuario'));

        API.get(`admin/propagandas`, { headers: { Authorization: `Bearer ${token}` } }).then((response) =>
            setPropagandas(response.data)
        );
    }, []);

    const apagaPropagandaHandler = (id) => setPropagandas(propagandas.filter((item) => item.id !== id));

    return (
        <div className={classes.lista}>
            <div className={classes.lista__header}>
                <Title title="Propagandas" />

                <button onClick={() => navigate('/admin/propagandas/create')}>
                    <img src={plusIcon} alt="plus icon" /> Adicionar
                </button>
            </div>

            <ul className={classes.lista__itens}>
                {propagandas.map((item) => (
                    <Propaganda key={item.id} {...item} apagaPropaganda={apagaPropagandaHandler} />
                ))}
            </ul>
        </div>
    );
};

export default ListaPropagandas;
