import React from 'react';
import classes from './style.module.scss';
import animation from '../../../assets/images/desktop/animationValid.png';
import backgroundanimation from '../../../assets/images/desktop/backgroundanimation.png';

const index = ({ title, subtitle, secondary, description, image, videoUrl, ableBackground, ableButton }) => {
    return (
        <div className={classes.container}>
            <div className={classes.sub_container}>
                <div>
                    <h2 className={classes.title}>{title}</h2>
                    <h5 className={classes.secondary_title}>{secondary}</h5>
                    <h3 className={classes.subtitle}>{subtitle}</h3>
                </div>

                <p className={classes.description}>{description}</p>
                {ableButton && (
                    <a href="https://wa.me/5544997525279" target="_blank" className={classes.button}>
                        CONHEÇA
                    </a>
                )}
            </div>
            <div className={classes.ctn_image}>
                {ableBackground && (
                    <img src={backgroundanimation} alt="fundo-com-gradiente" className={classes.fundo} />
                )}
                <img src={image} alt={image} />
                <div
                    style={{
                        width: '60%',

                        position: 'absolute',
                    }}
                >
                    <iframe
                        width="100%"
                        src={videoUrl}
                        title="O que a MINUS faz?"
                        frameBorder="0"
                        height={'350px'}
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default index;
