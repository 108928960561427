import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import API from '../../../api';

import classes from '../Form.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';

const EdicaoPermissoes = () => {
    const { state } = useLocation();
    const [isAdmin, setIsAdmin] = useState(state.usuario.is_admin === 1 ? true : false);
    const navigate = useNavigate();

    const { token } = JSON.parse(sessionStorage.getItem('usuario'));

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const submitFormHandler = (event) => {
        event.preventDefault();

        const data = {
            id: state.usuario.id,
            is_admin: isAdmin ? 1 : 0,
            _method: 'PATCH',
        };

        API.post(`admin/usuarios/permissoes`, data, config).then((response) => {
            navigate('/admin/usuarios');
        });
    };

    return (
        <div className={classes.formulario}>
            <Title title="Editar Usuário" />

            <form className={classes.formulario__form} onSubmit={submitFormHandler}>
                <div className={classes.formulario__card}>
                    <label htmlFor="nome">Nome: {state.usuario.nome}</label>
                </div>

                <div className={classes.formulario__radio}>
                    <label>Permissão de admin:</label>

                    <div className={classes.formulario__radio_grid}>
                        <div className={classes.formulario__radio_input}>
                            <input
                                type="radio"
                                name="isAdmin"
                                id="isAdminTrue"
                                checked={isAdmin}
                                onChange={() => setIsAdmin(true)}
                            />
                            <label htmlFor="isAdminTrue">Sim</label>
                        </div>

                        <div className={classes.formulario__radio_input}>
                            <input
                                type="radio"
                                name="isAdmin"
                                id="isAdminFalse"
                                checked={!isAdmin}
                                onChange={() => setIsAdmin(false)}
                            />
                            <label htmlFor="isAdminFalse">Não</label>
                        </div>
                    </div>
                </div>

                <button>Editar Permissão</button>
            </form>
        </div>
    );
};

export default EdicaoPermissoes;
