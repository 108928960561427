import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import SideMenu from '../../components/admin/SideMenu.jsx';
import classes from './ProtectedRoute.module.scss';

const ProtectedRoute = () => {
    const user = JSON.parse(sessionStorage.getItem('usuario'));

    if (!user.token) {
        return <Navigate to="/admin/login" />;
    }

    return (
        <div>
            <SideMenu />

            <div className={classes.content}>
                <Outlet />
            </div>
        </div>
    );
};

export default ProtectedRoute;
