import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import plusIcon from '../../../assets/icons/add.svg';
import classes from '../Lista.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';
import Video from './Video';

const ListaVideos = () => {
    const [videos, setVideos] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const { token } = JSON.parse(sessionStorage.getItem('usuario'));

        API.get(`admin/videos`, { headers: { Authorization: `Bearer ${token}` } })
            .then((response) => setVideos(response.data));
    }, []);

    const apagaVideoHandler = (id) => setVideos(videos.filter((item) => item.id !== id));

    return (
        <div className={classes.lista}>
            <div className={classes.lista__header}>
                <Title title="Videos" />

                <button onClick={() => navigate('/admin/videos/create')}>
                    <img src={plusIcon} alt="plus icon" /> Adicionar
                </button>
            </div>

            <ul className={classes.lista__itens}>
                {videos.map((item) => (
                    <Video key={item.id} {...item} apagaVideo={apagaVideoHandler} />
                ))}
            </ul>
        </div>
    );
};

export default ListaVideos;
