import React from 'react';
import classes from './style.module.scss';
import { Link } from 'react-router-dom';

const index = ({ title, imgUrl, UrlNavigation }) => {
    return (
        <div className={classes.ctn_btn}>
            <Link to={UrlNavigation} className={classes.btn_main}>
                {title}
            </Link>
            <div className={classes.btn_icon}>
                <img src={imgUrl} alt={imgUrl} />
            </div>
        </div>
    );
};

export default index;
