import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import classes from '../Form.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';

const NovoUsuario = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmaSenha, setConfirmaSenha] = useState('');
    const [funcao, setFuncao] = useState('');
    const [biografia, setBiografia] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [isAdmin, setIsAdmin] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    // Validações
    const [validacaoNome, setValidacaoNome] = useState({ valido: true, mensagem: '' });
    const [validacaoEmail, setValidacaoEmail] = useState({ valido: true, mensagem: '' });
    const [validacaoSenha, setValidacaoSenha] = useState({ valido: true, mensagem: '' });
    const [validacaoFuncao, setValidacaoFuncao] = useState({ valido: true, mensagem: '' });
    const [validacaoBiografia, setValidacaoBiografia] = useState({ valido: true, mensagem: '' });
    const [validacaoLinkedin, setValidacaoLinkedin] = useState({ valido: true, mensagem: '' });
    const [validacaoImagem, setValidacaoImagem] = useState({ valido: true, mensagem: '' });

    const navigate = useNavigate();

    const { token } = JSON.parse(sessionStorage.getItem('usuario'));

    const config = {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    };

    const submitFormHandler = (event) => {
        event.preventDefault();

        if (!formularioValido()) return false;

        const formData = new FormData();

        formData.append('image', selectedFile);
        formData.append('nome', nome);
        formData.append('email', email);
        formData.append('senha', senha);
        formData.append('funcao', funcao);
        formData.append('biografia', biografia);
        formData.append('linkedin', linkedin);
        formData.append('is_admin', isAdmin ? 1 : 0);

        API.post(`admin/usuarios`, formData, config).then((response) => {
            navigate('/admin/usuarios');
        });
    };

    const formularioValido = () => {
        if (
            !nomeValido() |
            !emailValido() |
            !senhaValida() |
            !funcaoValido() |
            !biografiaValido() |
            !linkedinValido() |
            !imagemValido()
        )
            return false;

        return true;
    };

    const nomeValido = () => {
        if (nome.trim() == '') {
            setValidacaoNome({ valido: false, mensagem: 'O Nome não pode estar vazio.' });
        } else if (nome.trim().length < 3) {
            setValidacaoNome({ valido: false, mensagem: 'O Nome não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoNome({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const emailValido = () => {
        if (email.trim() == '') {
            setValidacaoEmail({ valido: false, mensagem: 'O E-mail não pode estar vazio.' });
        } else if (email.trim().length < 3) {
            setValidacaoEmail({ valido: false, mensagem: 'O E-mail não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoEmail({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const funcaoValido = () => {
        if (funcao.trim() == '') {
            setValidacaoFuncao({ valido: false, mensagem: 'A Função não pode estar vazio.' });
        } else if (funcao.trim().length < 3) {
            setValidacaoFuncao({ valido: false, mensagem: 'A Função não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoFuncao({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const biografiaValido = () => {
        if (biografia.trim() == '') {
            setValidacaoBiografia({ valido: false, mensagem: 'A Biografia não pode estar vazio.' });
        } else if (biografia.trim().length < 3) {
            setValidacaoBiografia({ valido: false, mensagem: 'A Biografia não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoBiografia({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const linkedinValido = () => {
        if (linkedin.trim() == '') {
            setValidacaoLinkedin({ valido: false, mensagem: 'A Biografia não pode estar vazio.' });
        } else if (linkedin.trim().length < 3) {
            setValidacaoLinkedin({ valido: false, mensagem: 'A Biografia não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoLinkedin({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const imagemValido = () => {
        if (!selectedFile) {
            setValidacaoImagem({ valido: false, mensagem: 'É preciso adicionar uma imagem para o perfil.' });
        } else {
            setValidacaoImagem({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    function senhaValida() {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const passwordLength = senha.length;
        const uppercasePassword = uppercaseRegExp.test(senha);
        const lowercasePassword = lowercaseRegExp.test(senha);
        const digitsPassword = digitsRegExp.test(senha);
        const specialCharPassword = specialCharRegExp.test(senha);
        const minLengthPassword = minLengthRegExp.test(senha);

        if (passwordLength === 0) setValidacaoSenha({ valido: false, mensagem: 'A senha não pode estar vazia' });
        else if (!uppercasePassword)
            setValidacaoSenha({ valido: false, mensagem: 'A senha precisa de ao menos 1 caracter maiusculo' });
        else if (!lowercasePassword)
            setValidacaoSenha({ valido: false, mensagem: 'A senha precisa de ao menos 1 caracter minusculo' });
        else if (!digitsPassword)
            setValidacaoSenha({ valido: false, mensagem: 'A senha precisa de ao menos 1 digito' });
        else if (!specialCharPassword)
            setValidacaoSenha({ valido: false, mensagem: 'A senha precisa de ao menos 1 caracter especial' });
        else if (!minLengthPassword)
            setValidacaoSenha({ valido: false, mensagem: 'A senha precisa ter no mínimo 8 caracteres' });
        else if (senha !== confirmaSenha)
            setValidacaoSenha({ valido: false, mensagem: 'A confirmação de senha não esta igual' });
        else {
            setValidacaoSenha({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    }

    return (
        <div className={classes.formulario}>
            <Title title="Novo Usuário" />

            <form className={classes.formulario__form} onSubmit={submitFormHandler}>
                <div className={classes.formulario__two_columns}>
                    <div className={classes.formulario__card}>
                        <label htmlFor="nome">Nome:</label>
                        <input
                            className={!validacaoNome.valido ? classes.formulario__card_invalid : ''}
                            type="text"
                            name="nome"
                            id="nome"
                            onChange={(e) => setNome(e.target.value)}
                        />
                        {!validacaoNome.valido && <small>{validacaoNome.mensagem}</small>}
                    </div>
                    <div className={classes.formulario__card}>
                        <label htmlFor="funcao">Função:</label>
                        <input
                            className={!validacaoFuncao.valido ? classes.formulario__card_invalid : ''}
                            type="text"
                            name="funcao"
                            id="funcao"
                            onChange={(e) => setFuncao(e.target.value)}
                        />
                        {!validacaoFuncao.valido && <small>{validacaoFuncao.mensagem}</small>}
                    </div>
                </div>

                <div className={classes.formulario__card}>
                    <label htmlFor="email">E-mail:</label>
                    <input
                        className={!validacaoEmail.valido ? classes.formulario__card_invalid : ''}
                        type="email"
                        name="email"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!validacaoEmail.valido && <small>{validacaoEmail.mensagem}</small>}
                </div>

                <div className={classes.formulario__two_columns}>
                    <div className={classes.formulario__card}>
                        <label htmlFor="senha">Senha:</label>
                        <input
                            className={!validacaoSenha.valido ? classes.formulario__card_invalid : ''}
                            type="password"
                            name="senha"
                            id="senha"
                            onChange={(e) => setSenha(e.target.value)}
                        />
                    </div>

                    <div className={classes.formulario__card}>
                        <label htmlFor="confirmeSenha">Confirmação Senha:</label>
                        <input
                            className={!validacaoSenha.valido ? classes.formulario__card_invalid : ''}
                            type="password"
                            name="confirmeSenha"
                            id="confirmeSenha"
                            onChange={(e) => setConfirmaSenha(e.target.value)}
                        />
                    </div>
                    {!validacaoSenha.valido && <small>{validacaoSenha.mensagem}</small>}
                </div>

                <div className={classes.formulario__radio}>
                    <label>Permissão de admin:</label>

                    <div className={classes.formulario__radio_grid}>
                        <div className={classes.formulario__radio_input}>
                            <input type="radio" name="isAdmin" id="isAdminTrue" onChange={() => setIsAdmin(true)} />
                            <label htmlFor="isAdminTrue">Sim</label>
                        </div>

                        <div className={classes.formulario__radio_input}>
                            <input type="radio" name="isAdmin" id="isAdminFalse" onChange={() => setIsAdmin(false)} />
                            <label htmlFor="isAdminFalse">Não</label>
                        </div>
                    </div>
                </div>

                <div className={classes.formulario__card}>
                    <label htmlFor="linkedin">URL do Linkedin:</label>
                    <input
                        className={!validacaoLinkedin.valido ? classes.formulario__card_invalid : ''}
                        type="url"
                        name="linkedin"
                        id="linkedin"
                        onChange={(e) => setLinkedin(e.target.value)}
                    />
                    {!validacaoLinkedin.valido && <small>{validacaoLinkedin.mensagem}</small>}
                </div>

                <div className={classes.formulario__card}>
                    <label htmlFor="biografia">Biografia:</label>
                    <textarea
                        className={!validacaoBiografia.valido ? classes.formulario__card_invalid : ''}
                        name="biografia"
                        id="biografia"
                        rows="8"
                        onChange={(e) => setBiografia(e.target.value)}
                    ></textarea>
                    {!validacaoBiografia.valido && <small>{validacaoBiografia.mensagem}</small>}
                </div>

                <div className={classes.formulario__imagem}>
                    <label htmlFor="imagem">
                        Imagem de Perfil<small>(minimo: 140x140px - máximo:800x800px)</small>:
                    </label>
                    <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    {!validacaoImagem.valido && <small>{validacaoImagem.mensagem}</small>}
                </div>

                <button>Criar</button>
            </form>
        </div>
    );
};

export default NovoUsuario;
