import React from 'react';
import classes from './style.module.scss';
import Card from '../Cards';
import sirene from '../../../assets/images/desktop/sirene.png';
import solucao from '../../../assets/images/desktop/solucao-de-problemas.png';
import analise from '../../../assets/images/desktop/analise.png';

const index = ({ onScrollToForm }) => {
    return (
        <div className={classes.container}>
            <div id={classes.texto}>
                <h2>O ROTA NA PRÁTICA</h2>
                <p className={classes.texto}>
                    Com o Rota, você reduz tempo de contagem na operação, custos com inventário geral, rupturas virtuais
                    e do e-commerce, além de agilizar a correção do estoque sistêmico de maneira totalmente integrada ao
                    seu ERP.
                </p>
            </div>
            <div className={classes.cards}>
                <Card
                    image={sirene}
                    title="Analise com propriedade"
                    description="O ROTA lhe envia alertas de produtos possíveis de divergência (físico x sistêmico) e coleta informações por meio do aplicativo mobile para celulares ou coletores. Nossa aplicação opera online e offline."
                />
                <Card
                    image={solucao}
                    title="Atue estrategicamente"
                    description="Com o ROTA você trabalha focado na acuracidade do seu estoque através de dados concretos e atualizados sobre as suas operações e estoques físicos e sistêmicos."
                />
                <Card
                    image={analise}
                    title="Analise com propriedade"
                    description="Disponibiliza informações atualizadas de divergências e estoque, possibilita o acompanhamento de indicadores e o monitoramento da execução de atividades de cada uma de suas lojas."
                />
            </div>

            <button onClick={onScrollToForm} className={classes.btn_contato}>
                QUERO FALAR COM UM CONSULTOR
            </button>
        </div>
    );
};

export default index;
