import React, { useState } from 'react';

import classes from './Sobre.module.scss';

const Sobre = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return (
            <section id="sobre" className={classes.sobre}>
                {/* <div className={classes.sobre__text}>
                    <div className={classes.sobre_titulo}>
                        <h3>Sobre Nós</h3>
                    </div>
                    <p className={classes.paragrafos}>
                        A <span>Minus</span> é uma empresa de tecnologia para o varejo com sede em Maringá - PR,{' '}
                        <span>com soluções focadas na gestão de validades e na gestão de estoques.</span> Trabalhamos
                        para resolver alguns dos problemas mais complexos do varejo brasileiro.
                    </p>

                    <p className={classes.paragrafos}>
                        Nossos <span>sistemas substituem controles manuais e em planilhas,</span> comumente adotados no
                        varejo, por <span>tecnologia preditiva de ponta,</span>
                        que <span>utiliza estatística avançada e soluções mobile</span> para apresentar à loja os
                        principais produtos em risco de vencimento, e apontam aos gestores as ações que podem ser
                        tomadas para evitar o desperdício. Tudo isso{' '}
                        <span>desenvolvido por um time de especialista em varejo,</span> e que se preocupa de fato com o
                        desperdício no país. Nosso objetivo é o de adequar nossas soluções ao processo dos nossos
                        cliente, trazendo melhorias que mudem sua rotina para melhor e tragam resultados
                        representativos.
                    </p>
                </div> */}
            </section>
        );
    } else {
        return (
            <section id="sobre" className={classes.sobre}>
                <div className={classes.sobre__text}>
                    <div className={classes.sobre_titulo}>
                        <h3>Sobre Nós</h3>
                    </div>
                    <p className={classes.paragrafos}>
                        A <span>Minus</span> é uma empresa de tecnologia para o varejo com sede em Maringá - PR,{' '}
                        <span>com soluções focadas na gestão de validades e na gestão de estoques.</span> Trabalhamos
                        para resolver alguns dos problemas mais complexos do varejo brasileiro.
                    </p>

                    <p className={classes.paragrafos}>
                        Nossos <span>sistemas substituem controles manuais e em planilhas,</span> comumente adotados no
                        varejo, por <span>tecnologia preditiva de ponta,</span>
                        que <span>utiliza estatística avançada e soluções mobile</span> para apresentar à loja os
                        principais produtos em risco de vencimento, e apontam aos gestores as ações que podem ser
                        tomadas para evitar o desperdício. Tudo isso{' '}
                        <span>desenvolvido por um time de especialista em varejo,</span> e que se preocupa de fato com o
                        desperdício no país. Nosso objetivo é o de adequar nossas soluções ao processo dos nossos
                        cliente, trazendo melhorias que mudem sua rotina para melhor e tragam resultados
                        representativos.
                    </p>
                </div>
            </section>
        );
    }
};

export default Sobre;
