import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import classes from './Contato.module.scss';
import mailIcon from '../../../assets/icons/mail_black.svg';
import emailIcone from '../../../assets/images/desktop/correspondencia.png';
import whatsIcon from '../../../assets/images/desktop/whatsapp.png';
import phoneIcon from '../../../assets/icons/phone_black.svg';
import linkedinIcon from '../../../assets/icons/linkedin_black.svg';
import whatsappIcon from '../../../assets/icons/whatsapp_black.svg';
import mapIcon from '../../../assets/icons/map.svg';
import IconeLocalizacao from '../../../assets/images/desktop/iconLocation.png';
import clockIcon from '../../../assets/images/desktop/relogio1.png';

const Contato = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return (
            <div>
                <div className={classes.footer_top}></div>
                <section className={classes.contato__desktop}>
                    <div className={classes.contato__info}>
                        <div style={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
                            <img src={IconeLocalizacao} alt="sign icon" className={classes.img_contato} />
                            <p>
                                Edifício Marquezini Trade Center<br></br> Rua Neo Alves Martins, 2999, sala 46<br></br>{' '}
                                Maringá - PR{' '}
                            </p>
                        </div>

                        <div style={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
                            <img src={clockIcon} alt="" className={classes.img_contato} />
                            <p className={classes.contato_privacidade}>Atendimento 6h - 20h</p>
                        </div>
                    </div>

                    <div className={classes.contato__midia} style={{ display: 'flex', justifyContent: 'center' }}>
                        <div>
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', minWidth: '250px' }}>
                                <img src={emailIcone} alt="mail icon" className={classes.img_contato} />
                                <p>suporte@minus.eco.br </p>
                            </div>
                        </div>

                        <div
                            className={classes.contato__redes}
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                minWidth: '250px',
                            }}
                        >
                            <img className={classes.img_contato} src={whatsIcon} alt="whatsapp icon" />
                            <a
                                style={{ color: '#000', fontSize: '20px' }}
                                href="https://wa.me/5544997525279"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                (44) 99752-5279
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        );
    } else {
        return (
            <div>
                <div className={classes.footer_top}></div>
                <section className={classes.contato}>
                    <div className={classes.contato__info}>
                        <p>
                            <img src={mapIcon} alt="sign icon" />
                            Edifício Marquezini Trade Center, Rua Neo Alves Martins, 2999, sala 46
                        </p>
                        <p>
                            <img src={mailIcon} alt="mail icon" />
                            suporte@minus.eco.br
                        </p>
                        <p>
                            <img src={phoneIcon} alt="phone icon" />
                            (44) 99752-5279
                        </p>
                    </div>

                    <div className={classes.contato__midia}>
                        <div>
                            <p className={classes.contato_privacidade}>Atendimento 6h - 20h</p>
                        </div>

                        <div className={classes.contato__redes}>
                            <a href="https://wa.me/5544997525279" target="_blank" rel="noopener noreferrer">
                                <img src={whatsappIcon} alt="whatsapp icon" />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/minustecnologia"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={linkedinIcon} alt="linkedin icon" />
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
};

export default Contato;
