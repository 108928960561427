import React from 'react';

 const VideoSobre = () => {
    return (
        <div>
        <iframe
            width="100%"
            src="https://www.youtube.com/embed/9FIwKO4A0EU?modestbranding=1&rel=0&vq=hd1080"
            title="O que a MINUS faz?"
            frameBorder="0"
            height={"400px"}
            allowFullScreen
        ></iframe>
    </div>
    );
}


export default VideoSobre;