import React, { useState } from 'react';
import DadosEstatistica from '../components/layout/DadosEstatistica/DadosEstatistica';
import classes from '../App.module.scss';
import Headline from '../components/layout/Headline/Headline';
import Servicos from '../components/layout/Servicos';
import Sobre from '../components/layout/Sobre';
import Clientes from '../components/layout/Clientes/Clientes';
import Estatisticas from '../components/layout/Estatisticas/Estatisticas';
import Depoimento from '../components/layout/Depoimentos/Depoimento';
import Tecnologias from '../components/layout/Tecnologias';
import Market_logo from '../assets/icons/market_image.svg';

const HomePage = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return (
            <div>
                <Headline />
                <Depoimento />
                <Clientes />
                <Servicos />
                <DadosEstatistica />
                <Tecnologias />
                <Sobre />
            </div>
        );
    } else {
        return (
            <div>
                <Headline />
                <div className={classes.action}>
                    <div className={classes.image}>
                        <img src={Market_logo} />
                    </div>
                    <div className={classes.content}>
                        <div className={classes.header}>
                            <h2>COMO ANDAM SUAS PERDAS?</h2>
                        </div>
                        <div className={classes.middle}>
                            <h6 className={classes.middle_text}>
                                Conte com a <p>Minus</p> como sua <p>principal parceira para Gestão de Perdas</p>. Entre
                                em contato conosco e avalie a possibilidade de um <p>projeto piloto GRATUITO!</p>
                            </h6>
                        </div>
                    </div>
                    <div className={classes.aside}>
                        <a href="https://wa.me/5544997525279" target="_blank" rel="noopener noreferrer">
                            <button>Fale Conosco</button>
                        </a>
                    </div>
                </div>
                <Servicos />
                <Clientes />
                <Depoimento />
                <DadosEstatistica />
                <Tecnologias />
                <Sobre />
            </div>
        );
    }
};

export default HomePage;
