import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import plusIcon from '../../../assets/icons/add.svg';
import classes from '../Lista.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';
import Tag from './Tag';

const ListaTags = () => {
    const [tags, setTags] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const { token } = JSON.parse(sessionStorage.getItem('usuario'));

        API.get(`admin/tags`, { headers: { Authorization: `Bearer ${token}` } }).then((response) =>
            setTags(response.data)
        );
    }, []);

    const apagaTagHandler = (id) => setTags(tags.filter((item) => item.id !== id));

    return (
        <div className={classes.lista}>
            <div className={classes.lista__header}>
                <Title title="Tags" />

                <button onClick={() => navigate('/admin/tags/create')}>
                    <img src={plusIcon} alt="plus icon" /> Adicionar
                </button>
            </div>

            <ul className={classes.lista__itens_multiplos}>
                {tags.map((item) => (
                    <Tag key={item.id} {...item} apagaTag={apagaTagHandler} />
                ))}
            </ul>
        </div>
    );
};

export default ListaTags;
