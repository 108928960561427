import React from 'react';
import { Link } from 'react-router-dom';

import classes from './PostCard.module.scss';

const baseImg = process.env.REACT_APP_BASE_IMAGE;

const option = {
    year: 'numeric',
    month: 'short',
};
const locale = 'pt-br';

const PostCard = (props) => {
    return (
        <Link to={`/blog/${props.url_slug}`} className={classes.post}>
            <img src={`${baseImg}/thumb/${props.imagem_capa}`} alt="imagem do post" loading="lazy" />

            <div className={classes.post__content}>
                <h3>{props.titulo}</h3>

                <p>{props.descricao.substring(0, 80).concat('...')}</p>
                <small>
                    {new Date(props.data_publicacao).toLocaleDateString(locale, option).replace('. de ', '/')} | Leitura: {props.tempo_leitura}min
                </small>
            </div>
        </Link>
    );
};

export default PostCard;
