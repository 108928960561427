import React, { useState } from 'react';

import classes from './Depoimento.module.scss';
import Card from '../../layout/CardDepoimento/CardDepoimento';
import persona_verona from '../../../assets/images/persona_verona.jpg';
import persona_csd from '../../../assets/images/persona_csd.png';

const Depoimento = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return (
            <section id="depoimento" className={classes.depoimento__desktop}>
                <div className={classes.depoimento__desktop__text__desktop}>
                    <div>
                        <div className={classes.ctn_imagem__desktop}>
                            <img src={persona_verona} />
                            <p style={{ marginLeft: '0px' }} className={classes.depoimento__desktop__perfil__desktop}>
                                <span>André Ricardo Bettin</span>
                                <br></br>
                                Gerente de Prevenção de Perdas<br></br> Verona Supermercados
                            </p>
                        </div>
                        <div className={classes.ctn_depoimento}>
                            <p
                                style={{
                                    display: 'flex',
                                    marginRight: 'auto',
                                }}
                                className={classes.write__desktop}
                            >
                                "Excelente ferramenta, resultados comprovados. Todo gestor de perdas precisa conhecer,
                                recomendo."
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className={classes.ctn_imagem__desktop}>
                            <img src={persona_csd} />
                            <p style={{ marginLeft: '0px' }} className={classes.depoimento__desktop__perfil__desktop}>
                                <span>Wesley Batilana Gravena</span> <br />
                                Gerente de Prevenção de Perdas <br /> Grupo Amigão
                            </p>
                        </div>

                        <p className={classes.write__desktop}>
                            "O sistema da Minus é prático e rápido e se adequa muito bem aos processos da nossa empresa.
                            Além disso, todos os colaboradores conseguem utilizá-lo com facilidade no dia a dia, desde a
                            operação de loja até os setores de suporte, como GPP e Pricing."
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <section id="depoimento" className={classes.depoimento}>
                <div className={classes.titulo}>Depoimentos</div>

                <div className={classes.depoimento__text}>
                    <Card>
                        <img src={persona_verona} />
                        <p className={classes.write}>
                            "Excelente ferramenta, resultados comprovados. Todo gestor de perdas precisa conhecer,
                            recomendo."
                        </p>
                        <p className={classes.depoimento__perfil}>
                            <span>André Ricardo Bettin</span> - Gestor de Auditoria e Processos - Verona Supermercados
                        </p>
                    </Card>

                    <Card>
                        <img src={persona_csd} />
                        <p>
                            "O sistema da Minus é prático e rápido e se adequa muito bem aos processos da nossa empresa.
                            Além disso, todos os colaboradores conseguem utilizá-lo com facilidade no dia a dia, desde a
                            operação de loja até os setores de suporte, como GPP e Pricing."
                        </p>
                        <p className={classes.depoimento__perfil}>
                            <span>Wesley Batilana Gravena</span> - Gerente de Prevenção de Perdas - Grupo Amigão
                        </p>
                    </Card>
                </div>
            </section>
        );
    }
};

export default Depoimento;
