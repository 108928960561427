import React from 'react';

import classes from './ServicoItem.module.scss';
import Card from '../UI/CardServico.js';

const ServicoItem = (props) => {
    return (
        <div className={classes.servico}>
            <Card>
                <a href={props.sistemas}>
                    <h3>{props.title}</h3>
                    <p>{props.description}</p>
                    <div className={classes.button_servico}>
                        <button className={props.buttonClass}>{props.button}</button>
                    </div>
                </a>
            </Card>
        </div>
    );
};

export default ServicoItem;
