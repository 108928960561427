import React, { useState } from 'react';


import API from '../../../api';

import mailIcon from '../../../assets/icons/mail_full.svg';
import sendIcon from '../../../assets/icons/send.svg';
import classes from './SubscribeBlog.module.scss';
import Modal from '../../UI/Modal';

const Inscrever = () => {
    const [email, setEmail] = useState('');
    const [foiEnviado, setFoiEnviado] = useState('');
    const [showModal, setShowModal] = useState(false);

    const salvaEmailHandler = () => {
        API.post(`blog/interessados`, { email })
            .then((response) => {
                setFoiEnviado(true);
            })
            .catch((error) => {
                setFoiEnviado(false);
            })
            .finally(() => {
                setShowModal(true);

                setTimeout(() => {
                    setShowModal(false);
                }, 3500);
            });
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <section className={classes.action}>
            <div>
                <h3>
                    <strong>Inscreva-se</strong> <span className={classes.destaque}> em nosso</span> <strong>blog</strong>
                </h3>
                <p>Acesse, em primeira mão, nossos principais posts</p>
                <p>diretamente em seu email</p>
            </div>

            <div className={classes.action__sender}>
                <img className={classes.action__sender_icon} src={mailIcon} alt="mail icon" />
                <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                <button className={classes.button_valid} onClick={salvaEmailHandler}>
                    Enviar <img src={sendIcon} alt="send icon" />
                </button>
            </div>

            {showModal && (
                <Modal onClose={handleClose}>
                    {foiEnviado && (
                        <div className={classes.mensagem + ' ' + classes.sucesso}>
                            <h2>E-mail cadastrado com sucesso!</h2>
                            <p>Obrigado por se inscrever em nosso Blog</p>
                        </div>
                    )}

                    {!foiEnviado && (
                        <div className={classes.mensagem + ' ' + classes.erro}>
                            <h2>Erro ao cadastrar o email!</h2>
                            <p>Email ja cadastrado.</p>
                        </div>
                    )}
                </Modal>
            )}
        </section>
    );
};

export default Inscrever;
