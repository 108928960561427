import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import pinLojas from '../../../assets/images/desktop/pin1.png';
import perfilusuario from '../../../assets/images/desktop/perfilusuario.png';
import estrela from '../../../assets/images/desktop/estrela.png';
import classes from './style.module.scss';

const StoreCounter = () => {
    useEffect(() => {
        startCountUp();
        startCountUp2();
        startCountUp3();
    }, []);

    let startCountUp = null;

    let startCountUp2 = null;
    let startCountUp3 = null;

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-evenly',
                alignItems: 'center',
            }}
        >
            <div>
                <div className={classes.ctn_imagem}>
                    <img
                        src={pinLojas}
                        alt="numero-lojas-icone"
                        style={{ marginRight: '-35px' }}
                        className={classes.imagem}
                    />
                </div>
                <CountUp start={400} end={1100} duration={3} separator=".">
                    {({ countUpRef, start }) => {
                        startCountUp = start; // Armazena a função start na variável
                        return (
                            <div>
                                <p className={classes.sinal}>
                                    + <span className={classes.data} ref={countUpRef}></span>
                                </p>
                            </div>
                        );
                    }}
                </CountUp>
                <h1 className={classes.titulo}>lojas</h1>
            </div>
            <div>
                <div className={classes.ctn_imagem}>
                    <img src={perfilusuario} alt="usuario-icone" className={classes.imagem} />
                </div>
                <CountUp start={200} end={850} duration={4} separator=",">
                    {({ countUpRef, start }) => {
                        startCountUp2 = start; // Armazena a função start na variável
                        return (
                            <div>
                                <p className={classes.sinal}>
                                    + <span className={classes.data} ref={countUpRef} />
                                </p>
                            </div>
                        );
                    }}
                </CountUp>
                <h1 className={classes.titulo}>usuários</h1>
            </div>
            <div>
                <div className={classes.ctn_imagem}>
                    <img src={estrela} alt="estrela-icone" className={classes.imagem} />
                </div>
                <CountUp start={0} end={99} duration={3} separator=",">
                    {({ countUpRef, start }) => {
                        startCountUp3 = start; // Armazena a função start na variável
                        return (
                            <div>
                                <p className={classes.sinal}>
                                    <span className={classes.data} ref={countUpRef}></span> %
                                </p>
                            </div>
                        );
                    }}
                </CountUp>
                <h1 className={classes.titulo}>de satisfação</h1>
            </div>
        </div>
    );
};

export default StoreCounter;
