import React from 'react';

import Simulador from '../components/simulador/Simulador';

const SimuladorPerda = () => {
    return (
        <>
            <Simulador />
        </>
    );
};

export default SimuladorPerda;
