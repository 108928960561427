import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import API from '../../../api';

import classes from '../Form.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';

const EdicaoSenhaUsuario = () => {
    const { state } = useLocation();
    const [senha, setSenha] = useState('');
    const [confirmaSenha, setConfirmaSenha] = useState('');
    const [senhaErrada, setSenhaErrada] = useState('');
    const navigate = useNavigate();

    const { token } = JSON.parse(sessionStorage.getItem('usuario'));

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const submitFormHandler = (event) => {
        event.preventDefault();

        let validacao = validaSenha();

        if (validacao != '') return false;

        API.patch(`admin/usuarios/nova-senha`, { senha, id: state.usuario.id }, config).then((response) => {
            navigate('/admin/usuarios');
        });
    };

    function validaSenha() {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const passwordLength = senha.length;
        const uppercasePassword = uppercaseRegExp.test(senha);
        const lowercasePassword = lowercaseRegExp.test(senha);
        const digitsPassword = digitsRegExp.test(senha);
        const specialCharPassword = specialCharRegExp.test(senha);
        const minLengthPassword = minLengthRegExp.test(senha);

        let errMsg = '';

        if (passwordLength === 0) errMsg = 'A senha não pode estar vazia';
        else if (!uppercasePassword) errMsg = 'A senha precisa de ao menos 1 caracter maiusculo';
        else if (!lowercasePassword) errMsg = 'A senha precisa de ao menos 1 caracter minusculo';
        else if (!digitsPassword) errMsg = 'A senha precisa de ao menos 1 digito ';
        else if (!specialCharPassword) errMsg = 'A senha precisa de ao menos 1 caracter especial';
        else if (!minLengthPassword) errMsg = 'A senha precisa ter no mínimo 8 caracteres';
        else errMsg = '';

        setSenhaErrada(errMsg);

        if (senha !== confirmaSenha) {
            setSenhaErrada('A confirmação de senha não esta igual');

            errMsg = 'A confirmação de senha não esta igual';
        }

        return errMsg;
    }

    return (
        <div className={classes.formulario}>
            <Title title="Editar Usuário" />

            <form className={classes.formulario__form} onSubmit={submitFormHandler}>
                <div className={classes.formulario__card}>
                    <label htmlFor="nome">Nome: {state.usuario.nome}</label>
                </div>

                <div className={classes.formulario__two_columns}>
                    <div className={classes.formulario__card}>
                        <label htmlFor="senha">Senha:</label>
                        <input type="password" name="senha" id="senha" onChange={(e) => setSenha(e.target.value)} />
                    </div>

                    <div className={classes.formulario__card}>
                        <label htmlFor="confirmeSenha">Confirmação Senha:</label>
                        <input
                            type="password"
                            name="confirmeSenha"
                            id="confirmeSenha"
                            onChange={(e) => setConfirmaSenha(e.target.value)}
                        />
                    </div>
                    <small>{senhaErrada}</small>
                </div>

                <button>Editar Senha</button>
            </form>
        </div>
    );
};

export default EdicaoSenhaUsuario;
