import React from 'react';
import classes from './style.module.scss';

const index = ({ image, title, description }) => {
    return (
        <div className={classes.card}>
            <div className={classes.ctn_image}>
                <img src={image} alt="" />
            </div>
            <div className={classes.ctn_texto}>
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default index;
