import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import API from '../../../api';

import classes from '../Form.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';

const EdicaoVideo = () => {
    const { state } = useLocation();
    const [titulo, setTitulo] = useState(state.titulo);
    const [urlSlug, setUrlSlug] = useState(state.url_slug);
    const [videoFrame, setVideoFrame] = useState(state.video_frame);
    const [selectedFile, setSelectedFile] = useState();
    const [imagemAnterior, setImagemAnterior] = useState(state.imagem_capa);

    // Validações
    const [validacaoTitulo, setValidacaoTitulo] = useState({ valido: true, mensagem: '' });
    const [validacaoUrl, setValidacaoUrl] = useState({ valido: true, mensagem: '' });
    const [validacaoFrame, setValidacaoFrame] = useState({ valido: true, mensagem: '' });

    const navigate = useNavigate();

    const { token } = JSON.parse(sessionStorage.getItem('usuario'));

    const config = {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    };

    const submitFormHandler = (event) => {
        event.preventDefault();

        if (!formularioValido()) return false;

        const formData = new FormData();
        formData.append('_method', 'PATCH');

        formData.append('image', selectedFile);
        formData.append('titulo', titulo);
        formData.append('url_slug', urlSlug);
        formData.append('id', state.id);
        formData.append('video_frame', videoFrame);

        API.post(`admin/videos`, formData, config).then((response) => {
            navigate('/admin/videos');
        });
    };

    const formularioValido = () => {
        if (!tituloValido() | !urlValido() | !frameValido()) return false;

        return true;
    };

    const tituloValido = () => {
        if (titulo.trim() == '') {
            setValidacaoTitulo({ valido: false, mensagem: 'O título não pode estar vazio.' });
        } else if (titulo.trim().length < 3) {
            setValidacaoTitulo({ valido: false, mensagem: 'O título não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoTitulo({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const urlValido = () => {
        if (urlSlug.trim() == '') {
            setValidacaoUrl({ valido: false, mensagem: 'A URL não pode estar vazio.' });
        } else if (urlSlug.trim().length < 3) {
            setValidacaoUrl({ valido: false, mensagem: 'A URL não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoUrl({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const frameValido = () => {
        if (videoFrame.trim() == '') {
            setValidacaoFrame({ valido: false, mensagem: 'O iFrame do video não pode estar vazio.' });
        } else if (videoFrame.trim().length < 3) {
            setValidacaoFrame({ valido: false, mensagem: 'O iFrame do video não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoFrame({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    return (
        <div className={classes.formulario}>
            <Title title="Editar Video" />

            <form className={classes.formulario__form} onSubmit={submitFormHandler}>
                <div className={classes.formulario__two_columns}>
                    <div className={classes.formulario__card}>
                        <label htmlFor="titulo">Título:</label>
                        <input
                            className={!validacaoTitulo.valido ? classes.formulario__card_invalid : ''}
                            type="text"
                            name="titulo"
                            id="titulo"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                        />
                        {!validacaoTitulo.valido && <small>{validacaoTitulo.mensagem}</small>}
                    </div>

                    <div className={classes.formulario__card}>
                        <label htmlFor="url_slug">
                            Slug da URL<small>(sem acentos ou caracteres especiais)</small>:
                        </label>
                        <input
                            className={!validacaoUrl.valido ? classes.formulario__card_invalid : ''}
                            type="text"
                            name="url_slug"
                            id="url_slug"
                            value={urlSlug}
                            onChange={(e) => setUrlSlug(e.target.value)}
                        />
                        {!validacaoUrl.valido && <small>{validacaoUrl.mensagem}</small>}
                    </div>
                </div>

                <div className={classes.formulario__card}>
                    <label htmlFor="video_frame">iFrame do video:</label>
                    <textarea
                        className={!validacaoFrame.valido ? classes.formulario__card_invalid : ''}
                        name="video_frame"
                        id="video_frame"
                        rows="8"
                        value={videoFrame}
                        onChange={(e) => setVideoFrame(e.target.value)}
                    ></textarea>
                    {!validacaoFrame.valido && <small>{validacaoFrame.mensagem}</small>}
                </div>

                <img src={`${process.env.REACT_APP_BASE_IMAGE}/videos/${imagemAnterior}`} alt={titulo} />

                <div className={classes.formulario__imagem}>
                    <label htmlFor="imagem">
                        Imagem de Capa<small>(minimo: 200x200px - máximo:600x600px)</small>:
                    </label>
                    <input
                        type="file"
                        id="imagem"
                        accept="image/*"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                </div>

                <button>Editar</button>
            </form>
        </div>
    );
};

export default EdicaoVideo;
