import React, { useState } from 'react';
import classes from './Acuracidade.module.scss';
import grafico from '../.././../assets/images/alvo_perdas.png';
import compras from '../../../assets/images/compras_section.png';
import estatistca from '../../../assets/images/desktop/estatistica.png';
import phone from '../../../assets/images/desktop/phone.png';

const Acuracidade = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return (
            <div className={classes.container__desktop}>
                <div className={classes.conteudo__desktop}>
                    <div className={classes.section__desktop}>
                        <img className={classes.imagem} src={estatistca}></img>
                        <p className={classes.dados}>99,66%</p>
                        <h2 id="titulo_um" className={classes.titulo}>
                            <strong>de acuracidade de estoque</strong>
                        </h2>
                    </div>
                    <div className={classes.section__desktop}>
                        <img className={classes.imagem} src={phone}></img>
                        <p className={classes.dados}>60,65%</p>
                        <h2 className={classes.titulo} id={classes.titulo_right}>
                            de redução ruptura <br></br>no e-commerce
                        </h2>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={classes.container}>
                <div className={classes.conteudo}>
                    <div className={classes.section}>
                        <h2 id="titulo_um" className={classes.titulo}>
                            <strong>Acuracidade de estoque</strong>
                        </h2>
                        <img className={classes.imagem} src={grafico}></img>
                        <p className={classes.dados}>99,66%</p>
                    </div>
                    <div className={classes.section}>
                        <h2 className={classes.titulo}>
                            Redução ruptura no <br></br> e-commerce
                        </h2>
                        <img className={classes.imagem} src={compras}></img>
                        <p className={classes.dados}>60,65%</p>
                    </div>
                </div>
            </div>
        );
    }
};

export default Acuracidade;
