import React from 'react';

import Valid from '../components/valid/Valid';

const ValidSection = () => {
    return (
        <>
            <Valid />
        </>
    );
};

export default ValidSection;
