import React, { useEffect, useState, useRef } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';

import classes from './Header.module.scss';
import logoMinus from '../../../assets/images/minus.svg';
import mailIcon from '../../../assets/icons/mail.svg';
import phoneIcon from '../../../assets/icons/phone.svg';
import linkedinIcon from '../../../assets/icons/linkedin.svg';
import whatsappIcon from '../../../assets/icons/whatsapp.svg';
import menuIcon from '../../../assets/icons/menu.svg';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [stickyClass, setStickyClass] = useState('');
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;
    const [selectedOption, setSelectedOption] = useState('default');
    const navigation = useNavigate();
    const location = useLocation();
    const isHome = location.pathname === '/';

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        if (selectedValue !== 'default') {
            navigation(selectedValue);
        }
        setSelectedOption(selectedValue);
    };

    const options = [
        { value: 'default', label: 'Sistemas', disabled: true },
        { value: '/valid', label: 'Valid | Gestão de Validades' },
        { value: '/rota', label: 'Rota | Inventário Rotativo' },
    ];

    const menuHandler = () => setShowMenu(!showMenu);

    const navbarOffset = useRef();

    const stickNavbar = () => {
        if (window !== undefined) {
            const value = navbarOffset.current.clientHeight;
            window.scrollY > value ? setStickyClass(classes.sticky) : setStickyClass('');

            setShowMenu(false);
        }
    };

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 768) setShowMenu(false);
        }

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', stickNavbar);
    }, []);

    if (!isMobilePage) {
        return (
            <>
                <header className={classes.header__desktop} id="inicio">
                    <div className={classes.menu__desktop + ' ' + stickyClass} ref={navbarOffset}>
                        <Link to="/#inicio">
                            <img className={classes.logo} src={logoMinus} alt="logo_lopes" />
                        </Link>

                        {/* <button className={classes.menu__icon} onClick={menuHandler}>
                            <img src={menuIcon} alt="menu icon" />
                        </button> */}

                        <nav className={showMenu ? classes.active : ''}>
                            <Link to="/#inicio">Inicio</Link>
                            <Link to="/sobre">Sobre</Link>
                            <select
                                className={classes.sistemas}
                                value={isHome ? 'default' : selectedOption}
                                onChange={handleSelectChange}
                            >
                                {options.map((option) => (
                                    <option key={option.value} value={option.value} disabled={option.disabled}>
                                        {selectedOption === 'default' || selectedOption !== option.value
                                            ? option.label
                                            : option.label.split(' | ')[0]}
                                    </option>
                                ))}
                            </select>

                            <Link to="/#servicos">Conteúdo</Link>
                            <Link to="https://api.whatsapp.com/send?phone=5544999824060&text=Olá,%20gostaria%20de%20obter%20mais%20informações%20sobre%20os%20sistemas%20da%20Minus.%20Pode%20me%20ajudar?">
                                CONTATO
                            </Link>
                        </nav>
                    </div>

                    {showMenu && <div className={classes.overlay}></div>}
                </header>
            </>
        );
    } else {
        return (
            <header className={classes.header} id="inicio">
                <div className={classes.infos}>
                    <div className={classes.contato}>
                        <span>
                            <img src={mailIcon} alt="mail icon" /> E-mail: contato@minus.eco.br
                        </span>
                        <span>
                            <img src={phoneIcon} alt="phone icon" /> Telefone: (44) 99982-4060
                        </span>
                    </div>

                    <div className={classes.midia}>
                        <a
                            href="https://www.linkedin.com/company/minustecnologia"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={linkedinIcon} alt="linkedin icon" />
                        </a>
                        <a href="https://wa.me/5544997525279" target="_blank" rel="noopener noreferrer">
                            <img src={whatsappIcon} alt="whatsapp icon" />
                        </a>
                    </div>
                </div>

                <div className={classes.menu + ' ' + stickyClass} ref={navbarOffset}>
                    <Link to="/#inicio">
                        <img className={classes.logo} src={logoMinus} alt="logo_lopes" />
                    </Link>

                    <button className={classes.menu__icon} onClick={menuHandler}>
                        <img src={menuIcon} alt="menu icon" />
                    </button>

                    <nav className={showMenu ? classes.active : ''}>
                        <Link to="/#inicio">Home</Link>
                        <Link to="/#servicos">Sistemas</Link>
                        <Link to="/#sobre">Sobre</Link>
                        <Link to="/blog">Blog</Link>
                        <Link to="/simulador">Simulador</Link>
                        <Link to="https://api.whatsapp.com/send?phone=5544999824060&text=Olá,%20gostaria%20de%20obter%20mais%20informações%20sobre%20os%20sistemas%20da%20Minus.%20Pode%20me%20ajudar?">
                            Whatsapp
                        </Link>
                    </nav>
                </div>

                {showMenu && <div className={classes.overlay}></div>}
            </header>
        );
    }
};

export default Header;
