import React, { useState, useRef } from 'react';
import classes from './FormularioRota.module.scss';
import Formulario from '../valid/Formulario/FormularioValid';
import { Link } from 'react-router-dom';
import ModalPopUp from '../rota/popUpEmail';

const FormularioRota = React.forwardRef((props, ref) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [popUP, setPopUp] = useState(false);
    const [checkSegmento, setCheckSegmento] = useState(false);
    const isMobilePage = width <= 768;

    function handleSubmit(event) {
        event.preventDefault();

        const form = event.target;
        const fd = new FormData(event.target);
        const segmentoChannel = fd.getAll('segmento');
        const data = Object.fromEntries(fd.entries());
        data.acquisition = segmentoChannel;
        if (data.segmento === 'default') {
            setCheckSegmento(true);
            return; // Impede o envio do formulário
        }

        window.Email.send({
            Host: 'smtp.elasticemail.com',
            Username: 'andre.ajita@minus.eco.br',
            // Password: '9414E15939C00E475E0F1B454771FE05310B',
            Password: 'E4D3A1C551AA32AC58F32F54B7768402C2B6',
            To: 'andre.ajita@minus.eco.br',
            From: 'andre.ajita@minus.eco.br',
            Subject: '✔ Novo contato através do formulário ROTA:',
            Body: `Nome: ${data.name}<br>Email: ${data.email}<br>Telefone: ${data.telefone}<br>Empresa: ${data.empresa}<br>Segmento: ${data.segmento}`,
        })
            .then(() => {
                form.reset();
            })
            .catch((error) => alert('Erro ao enviar o e-mail: ' + error));
        setTimeout(() => {
            setPopUp(false);
        }, 3000);
        setPopUp(true);
    }

    if (!isMobilePage) {
        return (
            <>
                {!popUP && (
                    <form onSubmit={handleSubmit} className={classes.formulario__desktop} ref={ref}>
                        <h4 id={classes.titulo}> Preencha o formulário para conversar com um consultor</h4>
                        <label>Nome</label>
                        <input required type="text" name="name" id="name"></input>
                        <label>E-mail</label>
                        <input required type="email" name="email" id="email"></input>
                        <label>Telefone</label>
                        <input required type="number" min={0} minLength={9} id="fone" name="telefone"></input>
                        <label>Empresa</label>
                        <input required type="text" id="empresa" name="empresa"></input>
                        <label>Segmento</label>
                        <select id={classes.select_item} name="segmento">
                            <option value="default">Escolha</option>
                            <option value="farmacia">Farmácia</option>
                            <option value="mercado">Mercado</option>
                            <option value="outro">Outro</option>
                        </select>
                        {checkSegmento && <p id={classes.msg}>Selecione um segmento válido</p>}

                        <div className={classes.ctn_button}>
                            <button className={classes.button}>SOLICITAR CONTATO</button>
                        </div>

                        <Link to={'/privacidade'} id={classes.legenda}>
                            Ao informar seus dados, você está de acordo com a nossa Política de Privacidade.
                        </Link>
                    </form>
                )}
                {popUP && <ModalPopUp />}
            </>
        );
    } else {
        return (
            <div>
                <div className={classes.formulario}>
                    <label>Nome</label>
                    <input required type="text"></input>
                    <label>Telefone</label>
                    <input required type="number"></input>
                    <label>E-mail</label>
                    <input required type="email"></input>
                    <label>Cargo</label>
                    <input required type="text"></input>
                    <label>Rede</label>
                    <input type="text"></input>
                    <button>
                        <p>Baixe nossa apresentação!</p>
                    </button>
                </div>
                <div className={classes.texto}>
                    <p>
                        <span className={classes.destaque}>Baixe</span> nossa{' '}
                        <span className={classes.destaque}>apresentação</span> e entenda o processo ROTA e veja{' '}
                        <span className={classes.destaque}>resultado e benefícios!</span>
                    </p>
                </div>
            </div>
        );
    }
});

export default FormularioRota;
