import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../api';

import classes from './Login.module.scss';
import personIcon from '../../assets/icons/person.svg';
import lockIcon from '../../assets/icons/lock.svg';

const Login = () => {
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const navigate = useNavigate();

    const changeEmailHandler = (event) => {
        setEmail(event.target.value);
    };

    const changeSenhaHandler = (event) => {
        setSenha(event.target.value);
    };

    const submitFormHandler = (event) => {
        event.preventDefault();

        const data = {
            email,
            senha,
        };

        API.post(`admin/login`, data)
            .then((response) => {
                sessionStorage.setItem('usuario', JSON.stringify(response.data));
                navigate('/admin/postagens');
            })
            .catch(() => {
                setIsInvalid(true);
            });
    };

    return (
        <div className={classes.login}>
            <div className={classes.login__card}>
                <div className={classes.login__box}>
                    <h2 className={classes.login__title}>Login Admin</h2>

                    <form className={classes.form} onSubmit={submitFormHandler}>
                        <div className={classes.form__box}>
                            <span>
                                <img src={personIcon} alt="icone para user" />
                            </span>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="E-mail"
                                onChange={changeEmailHandler}
                            />
                        </div>

                        <div className={classes.form__box}>
                            <span>
                                <img src={lockIcon} alt="icone para cadeado" />
                            </span>
                            <input
                                type="password"
                                name="senha"
                                id="senha"
                                placeholder="********"
                                onChange={changeSenhaHandler}
                            />
                        </div>
                        {isInvalid && <div className={classes.form__invalid}>E-mail ou senha inválidos!</div>}

                        <button>Entrar</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
