import React from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import classes from './Propaganda.module.scss';

const Propaganda = (props) => {
    const navigate = useNavigate();

    const editaPropagandaHandler = (item) => {
        const propaganda = {
            id: item.id,
            titulo: item.titulo,
            url: item.url,
            filename: item.filename,
        }
        navigate('/admin/propagandas/edit', { state: propaganda });
    };

    const excluiPropagandaHandler = () => {
        const { token } = JSON.parse(sessionStorage.getItem('usuario'));

        props.apagaPropaganda(props.id);

        API.delete(`admin/propagandas`, {
            headers: { Authorization: `Bearer ${token}` },
            data: { id: props.id },
        });
    };

    return (
        <li className={classes.propaganda}>
            <h3>{props.titulo}</h3>

            <div className={classes.propaganda__info}>
                <div className={classes.propaganda__detalhes}>
                    <strong>URL:</strong>
                    <span>{props.url}</span>
                </div>
            </div>

            <div className={classes.propaganda__acoes}>
                <button onClick={() => editaPropagandaHandler(props)}>Editar</button>
                <button onClick={() => excluiPropagandaHandler(props)}>Excluir</button>
            </div>
        </li>
    );
};

export default Propaganda;
