import React from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import classes from './Video.module.scss';

const Video = (props) => {
    const navigate = useNavigate();

    const editaVideoHandler = (item) => {
        const data = {
            id: item.id,
            titulo: item.titulo,
            url_slug: item.url_slug,
            imagem_capa: item.imagem_capa,
            video_frame: item.video_frame,
        }
        navigate('/admin/videos/edit', { state: data });
    };

    const excluiVideoHandler = () => {
        const { token } = JSON.parse(sessionStorage.getItem('usuario'));

        props.apagaVideo(props.id);

        API.delete(`admin/videos`, {
            headers: { Authorization: `Bearer ${token}` },
            data: { id: props.id },
        });
    };

    return (
        <li className={classes.video}>
            <h3>{props.titulo}</h3>

            <div className={classes.video__info}>
                <div className={classes.video__detalhes}>
                    <strong>Visualizações:</strong>
                    <span>{props.visualizacoes}</span>
                </div>
            </div>

            <div className={classes.video__acoes}>
                <button onClick={() => editaVideoHandler(props)}>Editar</button>
                <button onClick={() => excluiVideoHandler(props)}>Excluir</button>
            </div>
        </li>
    );
};

export default Video;
