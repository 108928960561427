import React from 'react';
import classes from './style.module.scss';

const index = ({ image, title, content }) => {
    return (
        <div className={classes.container}>
            <div className={classes.fundo}>
                <img src={image} alt="" className={classes.imagem} />
            </div>
            <div className={classes.texto}>
                <h2>{title}</h2>
                <p>{content}</p>
            </div>
        </div>
    );
};

export default index;
