import React from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './Post.module.scss';

const Post = (props) => {
    const navigate = useNavigate();

    const editaPostHandler = (item) => {
        const post = {
            id: item.id,
            titulo: item.titulo,
            tempoLeitura: item.tempo_leitura,
            descricao: item.descricao,
            conteudo: item.conteudo,
            prioridade: item.prioridade,
            imagem_capa: item.imagem_capa,
            urlSlug: item.url_slug,
            tagNome: item.tag_nome,
        }

        navigate('/admin/postagens/edit', { state: {post} });
    };

    return (
        <li className={classes.posts}>
            <h3>{props.titulo}</h3>

            <div className={classes.posts__info}>
                <div className={classes.posts__detalhes}>
                    <strong htmlFor="usuario">Usuário:</strong>
                    <span>{props.nome}</span>
                </div>

                <div className={classes.posts__detalhes}>
                    <strong htmlFor="publicacao">Data Publicação:</strong>
                    <span>{props.publicado}</span>
                </div>

                <div className={classes.posts__detalhes}>
                    <strong htmlFor="views">Visualizações:</strong>
                    <span>{props.visualizacoes}</span>
                </div>

                <div className={classes.posts__detalhes}>
                    <strong htmlFor="views">Prioridade:</strong>
                    <span>{(props.prioridade === 0) ? 'Sem Prioridade' : props.prioridade}</span>
                </div>

                <div className={classes.posts__detalhes}>
                    <strong htmlFor="views">Tags:</strong>
                    <span>{props.tag_nome}</span>
                </div>
            </div>

            <div className={classes.posts__descricao}>
                <strong htmlFor="descricao">Descrição:</strong>
                <span>{props.descricao}</span>
            </div>

            <div className={classes.posts__acoes}>
                <button onClick={() => editaPostHandler(props)}>Editar</button>
                {props.arquivado && (
                    <button className={classes.posts__acoes_recupera} onClick={() => props.onArquivaPost(props.id)}>
                        Recuperar
                    </button>
                )}
                {!props.arquivado && <button onClick={() => props.onArquivaPost(props.id)}>Arquivar</button>}
            </div>
        </li>
    );
};

export default Post;
