import React from 'react';
import classes from './SistemasRota.module.scss';

const SistemasRota = () => {

    return (
        <div className={classes.container}>
            <h1 className={classes.titulo}>
               <strong> Vantagens</strong> <span className={classes.destaque}>do Sistema</span> <strong>ROTA</strong>
            </h1>
            <p className={classes.paragrafos}>
            Com o Rota, você reduz o tempo de contagem na operação, os custos com inventário geral, as rupturas virtuais e do e-commerce, e agiliza a correção do estoque sistêmico de maneira totalmente integrada com seu ERP.
            </p>
        </div>
    )
}

export default SistemasRota;