import React from 'react';
import { Link } from 'react-router-dom';

import classes from './NotFound.module.scss';

const NotFound = () => {
    return (
        <React.Fragment>
            <div className={classes.bg_image}></div>
            <div className={classes.error}>
                <h1>404</h1>
                <h3>Página Não Encontrada</h3>
                <p>A página que você esta procurando não existe</p>

                <Link to="/">Ir Para Início</Link>
            </div>
        </React.Fragment>
    );
};

export default NotFound;
