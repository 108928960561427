import React from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import classes from './Tag.module.scss';

const Tag = (props) => {
    const navigate = useNavigate();

    const editaTagHandler = (item) => {
        const tag = {
            id: item.id,
            nome: item.nome
        }
        navigate('/admin/tags/edit', { state: tag });
    };

    const excluiTagHandler = () => {
        const { token } = JSON.parse(sessionStorage.getItem('usuario'));

        props.apagaTag(props.id);

        API.delete(`admin/tags`, {
            headers: { Authorization: `Bearer ${token}` },
            data: { id: props.id },
        });
    };

    return (
        <li className={classes.tag}>
            <h3>{props.nome}</h3>

            <div className={classes.tag__acoes}>
                <button onClick={() => editaTagHandler(props)}>Editar</button>
                <button onClick={() => excluiTagHandler(props)}>Excluir</button>
            </div>
        </li>
    );
};

export default Tag;
