import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import API from '../../api';
import classes from './Blog.module.scss';
import PostCard from '../../components/blog/PostCard';
import Inscrever from '../../components/blog/Inscrever';

const baseImg = process.env.REACT_APP_BASE_IMAGE;

const option = {
    year: 'numeric',
    month: 'short',
};

const locale = 'pt-br';

const Blog = () => {
    const [postagens, setPostagens] = useState([]);
    const [videos, setVideos] = useState([]);
    const [postagemPrincipal, setPostagemPrincipal] = useState('');
    const [postagensSecundarias, setPostagensSecundarias] = useState([]);
    const [postagensMaisVisualizadas, setPostagensMaisVisualizadas] = useState([]);

    useEffect(() => {
        API.get(`blog/recentes`).then((response) => setPostagens(response.data));
        API.get(`blog/mais-vistos`).then((response) => setPostagensMaisVisualizadas(response.data));
        API.get(`blog/videos`).then((response) => setVideos(response.data));

        API.get(`blog/principais`).then((response) => {
            let postagens = response.data;
            setPostagemPrincipal(postagens[0]);

            postagens.shift();

            setPostagensSecundarias(postagens);
        });
    }, []);

    const postsRecentElement = postagensSecundarias.map((post) => {
        return (
            <Link to={post.url_slug} className={classes.recentes__card} key={post.id}>
                <img src={`${baseImg}/thumb/${post.imagem_capa}`} alt="imagem do post" loading="lazy" />

                <div className={classes.recentes__content}>
                    <h2>{post.titulo}</h2>

                    <p>{post.descricao.substring(0, 150).concat('...')}</p>
                    <small>
                        {new Date(post.data_publicacao).toLocaleDateString(locale, option).replace('. de ', '/')} |
                        Leitura: {post.tempo_leitura}min
                    </small>
                </div>
            </Link>
        );
    });

    const postsElement = postagens.map((post) => {
        return (
            <Link to={post.url_slug} className={classes.noticias__recentes_card} key={post.id}>
                <img src={`${baseImg}/thumb/${post.imagem_capa}`} alt="imagem do post" />

                <div>
                    <h3>{post.titulo}</h3>

                    <p>{post.descricao.substring(0, 80).concat('...')}</p>
                    <small>
                        {new Date(post.data_publicacao).toLocaleDateString(locale, option).replace('. de ', '/')} |
                        Leitura: {post.tempo_leitura}min
                    </small>
                </div>
            </Link>
        );
    });

    return (
            <main className={classes.main}>
            <section className={classes.wrapper + ' ' + classes.destaque}>
                <div className={classes.destaque__content}>
                    <h1>{postagemPrincipal.titulo}</h1>

                    <p>{postagemPrincipal.descricao}</p>
                    <small>
                        {new Date(postagemPrincipal.data_publicacao)
                            .toLocaleDateString(locale, option)
                            .replace('. de ', '/')}{' '}
                        | Leitura: {postagemPrincipal.tempo_leitura}min
                    </small>

                    <Link to={postagemPrincipal.url_slug}>Ler Mais</Link>
                </div>
                <img src={`${baseImg}/thumb/${postagemPrincipal.imagem_capa}`} alt="imagem do post" />
            </section>

            <section className={classes.wrapper + ' ' + classes.recentes}>{postsRecentElement}</section>

            <Inscrever />

            {videos.length > 0 && (
                <section className={classes.wrapper + ' ' + classes.videos}>
                    <div className={classes.videos__titulo}>
                        <h2>Seleção de Videos</h2>
                        {videos.length >= 4 && <Link to={'/blog/videos'}>Ver todos videos</Link>}
                    </div>

                    <div className={classes.videos__posts}>
                        {videos.map((video) => (
                            <Link to={`/blog/video/${video.url_slug}`} key={video.id}>
                                <img src={`${baseImg}/videos/${video.imagem_capa}`} alt={video.titulo} />
                                <h4>{video.titulo}</h4>
                            </Link>
                        ))}
                    </div>
                </section>
            )}

            <section className={classes.wrapper + ' ' + classes.noticias}>
                {postagens.length > 0 && (
                    <div className={classes.noticias__order}>
                        <div className={classes.noticias__titulo}>
                            <h2>Veja Tambem</h2>
                            {postagens.length > 4 && <Link to={'/blog/postagens'}>Ver todas as notícias</Link>}
                        </div>

                        <div className={classes.noticias__recentes}>{postsElement}</div>
                    </div>
                )}

                {postagensMaisVisualizadas.length > 0 && (
                    <aside>
                        <h2>Mais lidos</h2>

                        <div className={classes.melhores}>
                            {postagensMaisVisualizadas.map((post) => (
                                <PostCard key={post.id} {...post} />
                            ))}
                        </div>
                    </aside>
                )}
            </section>
        </main>
    );
};

export default Blog;
