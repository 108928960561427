import React, { useEffect, useState } from 'react';

import API from '../../api';

import classes from './Postagens.module.scss';
import PostCard from '../../components/blog/PostCard';
import Title from '../../components/UI/Title';

const Postagens = () => {
    const [postagens, setPostagens] = useState([]);

    useEffect(() => {
        API.get(`blog/postagens`).then((response) => setPostagens(response.data));
    }, []);

    return (
        <main className={classes.main}>
            <Title title="Notícias" />

            <div className={classes.wrapper + ' ' + classes.noticias}>
                {postagens.map((post) => (
                    <PostCard key={post.id} {...post} />
                ))}
            </div>
        </main>
    );
};

export default Postagens;
