import React from 'react';
import classes from './Privacidade.module.scss';
import Title from '../components/UI/Title';

const Privacidade = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Title title="Política de Privacidade - MINUS TECNOLOGIA LTDA" />

            <section className={classes.textos}>
                <p>
                    Na <strong>Minus Tecnologia</strong>, privacidade e segurança são prioridades e nos comprometemos
                    com a transparência do tratamento de dados pessoais dos nossos clientes, colaboradores e parceiros.
                    Por isso, esta Política de Privacidade destina-se a esclarecer como tratamos tais dados e para quais
                    finalidades. Além disso, nesta Política você entenderá quais são os seus direitos como titular de
                    dados, de acordo com a Lei Geral de Proteção de Dados (LGPD).
                </p>

                <section className={`${classes.textos__sublist} ${classes.textos__normas}`}>
                    <article>
                        <h3>LEI GERAL DE PROTEÇÃO DE DADOS (LGPD)</h3>
                        <p>
                            A Lei Geral de Proteção de Dados (LGPD) é a legislação brasileira que protege os direitos
                            fundamentais de liberdade e privacidade dos titulares de dados, ao estabelecer regras e
                            diretrizes sobre o tratamento de Dados Pessoais, em meio físico ou digital.
                            <br />
                            <br />
                            Para a legislação, qualquer operação realizada com Dados Pessoais é considerada tratamento
                            de dados, abrangendo desde a coleta até o armazenamento. O titular de dados é a pessoa
                            física a quem esses dados se referem, enquanto os Dados Pessoais englobam qualquer
                            informação relacionada à pessoa física identificada ou identificável.
                            <br />
                            <br />
                            Além disso, a LGPD define os Dados Pessoais Sensíveis, que incluem informações sobre origem
                            racial ou étnica, convicções religiosas, opiniões políticas, entre outros aspectos
                            intimamente ligados à individualidade de uma pessoa.
                        </p>
                    </article>

                    <article>
                        <h3>OPERAÇÕES DE TRATAMENTO DE DADOS E SUAS FINALIDADES</h3>
                        <p>
                            Dependendo da interação do titular de dados conosco, coletamos algumas informações pessoais.
                            Em contexto comercial, podemos coletar dados pessoais de contato, como nome, e-mail, cargo e
                            telefone dos sócios, prepostos e/ou colaboradores de empresas interessadas em nossos
                            serviços, com a finalidade de manter relacionamentos e atrair leads para prospecção de
                            potenciais clientes, com fundamento em nosso legítimo interesse, nos termos do art. 7, inc.
                            IX da LGPD.
                            <br />
                            <br />
                            No caso de interesse em nossos serviços, tratamos informações adicionais do sócio
                            administrador, como nome completo, estado civil, profissão, RG, CPF e endereço (dados de
                            qualificação) para a conclusão da venda e elaboração do Contrato de Prestação de Serviços e
                            outros documentos jurídicos complementares.
                            <br />
                            <br />
                            No âmbito operacional, lidamos com dados identificados e identificáveis de colaboradores,
                            prestadores de serviços, prepostos e sócios de nossas empresas clientes, tais como nome,
                            dados de login, geolocalização, dados do dispositivo de acesso à plataforma.
                            <br />
                            <br />
                            Nós também processamos dados pessoais de currículos para fins de recrutamento. Em caso de
                            aprovação, solicitamos outras informações pessoais adicionais, necessárias para o processo
                            admissional, a fim de utilizá-los para a execução do contrato de trabalho ou de prestação de
                            serviços com o colaborador ou parceiro. Frisa-se que os dados pessoais sensíveis processados
                            no desenvolvimento das atividades relacionadas à Recursos Humanos são tratados com
                            fundamento no cumprimento de obrigação legal, no exercício regular de direitos – inclusive
                            em contrato – ou até mesmo para garantia da prevenção à fraude e à segurança do titular de
                            dados (Art. 11, inc. II, “a”, “d” e “g” da LGPD).
                            <br />
                            <br />
                            Por fim, é importante ressaltar que todos os dados pessoais tratados são limitados ao mínimo
                            necessário para o cumprimento de suas finalidades específicas e informadas nesta política de
                            privacidade.
                        </p>
                    </article>

                    <article>
                        <h3>COMPARTILHAMENTO DE DADOS</h3>
                        <div>
                            <p>
                                A depender da atividade, compartilhamos dados pessoais com empresas parceiras que
                                fornecem tecnologia da informação, sistemas que auxiliam e automatizam atividades
                                empresariais, cuja finalidade é aumentar a produtividade, viabilizar e melhorar a nossa
                                prestação de serviços, além de cumprir com as obrigações previstas em contrato com os
                                clientes e colaboradores com maior agilidade e eficácia.
                            </p>
                            <p>
                                Os dados dos nossos colaboradores, limitados ao mínimo necessário para o atendimento das
                                finalidades específicas, são compartilhados com órgãos públicos para o cumprimento de
                                obrigação legal, bem como com empresas parceiras que prestam serviços contábeis,
                                jurídicos e com as que fornecem benefícios corporativos, quando necessário para a
                                execução de contrato ou indispensável para o exercício regular de direitos, inclusive em
                                contratos, e em processo judicial, com fundamento no art. 7º, inc. V e VI; e art. 11,
                                inc. II, alínea d da LGPD.
                            </p>
                        </div>
                    </article>

                    <article>
                        <h3>TEMPO DE ARMAZENAMENTO</h3>
                        <p>
                            Os dados pessoais são mantidos em nosso banco de dados durante o período necessário para a
                            prestação de nossos serviços ou para o cumprimento das finalidades descritas nesta Política
                            de Privacidade.
                            <br />
                            <br />
                            Após o alcance das finalidades, os dados pessoais serão conservados para o cumprimento de
                            nossas obrigações legais. Sendo assim, o descarte dos dados somente ocorrerá após os prazos
                            prescricionais pelos quais estamos sujeitos.
                            <br />
                            <br />
                            Para que a nossa base de dados armazenados se mantenha protegida contra perda, destruição,
                            falsificação e acesso não autorizado, utilizamos uma variedade de controles de segurança:
                            hierarquização de acessos (controle de usuários), backups diários, políticas de gestão de
                            usuários, dentre outros.
                        </p>
                    </article>

                    <article>
                        <h3>SEGURANÇA DE DADOS</h3>
                        <p>
                            Nós assumimos o compromisso de implementar constantemente medidas eficazes e apropriadas
                            para manter a confidencialidade e a segurança dos dados pessoais, empenhando-nos ao máximo
                            para garantir e cumprir integralmente as exigências legislativas. Essa diligência reflete
                            nosso comprometimento com a proteção da privacidade e a segurança das informações dos nossos
                            clientes, colaboradores e parceiros. Dentre nossas práticas, incluem-se:
                        </p>
                        <div>
                            <p>
                                Armazenamento em banco de dados com controle de acesso por nível hierárquico no Amazon
                                RDS.
                            </p>
                            <p>
                                Políticas de gestão de usuários, acessos e segurança no Amazon Cognito, AWS IAM e AWS
                                KMS.
                            </p>
                            <p>
                                Backup automatizado de dados no AWS S3; juntamente com segurança e análise de tráfego
                                externo utilizando o Amazon Cloudtrail.
                            </p>
                        </div>
                        <p>
                            Referidas proteções não se aplicam a informações que o titular de dados tenha compartilhado
                            em áreas públicas, como redes sociais de terceiros.
                        </p>
                    </article>

                    <article>
                        <h3>DIREITO DOS TITULARES DE DADOS</h3>
                        <div className={classes.left_space}>
                            <p className={classes.ponteiro}>
                                Como titular de Dados Pessoais, você tem direitos garantidos pela Lei Geral de Proteção
                                de Dados, incluindo:
                            </p>

                            <p class={classes.ponteiro}>
                                Acesso, revisão e confirmação do tratamento dos seus Dados Pessoais, com a possibilidade
                                de solicitar uma cópia eletrônica das informações que temos sobre você.
                            </p>
                            <p class={classes.ponteiro}>
                                Correção de Dados Pessoais incompletos, inexatos ou desatualizados.
                            </p>
                            <p class={classes.ponteiro}>
                                Solicitação de anonimização, bloqueio ou eliminação de Dados Pessoais desnecessários ou
                                excessivos.
                            </p>
                            <p class={classes.ponteiro}>
                                Portabilidade dos seus Dados Pessoais para outro fornecedor de produtos ou serviços
                                similares.
                            </p>
                            <p class={classes.ponteiro}>
                                Eliminação dos Dados Pessoais coletados e utilizados com base no seu consentimento.
                            </p>
                            <p class={classes.ponteiro}>
                                Informações sobre as entidades públicas ou privadas com as quais compartilhamos seus
                                Dados Pessoais.
                            </p>
                            <p class={classes.ponteiro}>
                                Recusa ao consentimento quando necessário, com conhecimento das consequências.
                            </p>
                            <p class={classes.ponteiro}>
                                Revogação do consentimento a qualquer momento, quando aplicável.
                            </p>

                            <p class={classes.ponteiro}>
                                Para exercer esses direitos, utilize nosso canal de comunicação oficial disponibilizado
                                abaixo. Estamos comprometidos em garantir que você possa exercer seus direitos de
                                maneira transparente e eficiente.
                            </p>
                        </div>
                    </article>

                    <article>
                        <h3>CANAL DE COMUNICAÇÃO AO TITULAR DE DADOS</h3>
                        <p>
                            Após revisar nossa Política de Privacidade, caso deseje exercer seus direitos ou tenha
                            alguma dúvida, comentário ou sugestão sobre nossas práticas de privacidade e proteção de
                            Dados Pessoais, por favor, encaminhe sua solicitação por e-mail: lgpd@minus.eco.br.
                            <br />
                            <br />
                            Estamos à disposição para auxiliá-lo e receber seus feedbacks.
                        </p>
                    </article>

                    <article>
                        <h3>ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</h3>
                        <p>
                            Se houver alguma alteração nos termos desta Política de Privacidade, todos os titulares
                            poderão consultar as novas diretrizes através do nosso site:{' '}
                            <a
                                id={classes.ancora_privacidade}
                                href="https://minus.eco.br/privacidade"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://minus.eco.br/privacidade
                            </a>
                            .
                            <br />
                            <br />
                            Reservamo-nos o direito de modificar nossas práticas e esta Política a qualquer momento, em
                            conformidade com as condições da empresa e as tecnologias disponíveis no mercado.
                        </p>
                    </article>
                </section>
            </section>
        </div>
    );
};

export default Privacidade;
