import React from 'react';
import CardBlog from './SectionBlogCard';
import image_abrappe from '../../../assets/images/image_forum_abrappe.jpg';
import image_perdas from '../../../assets/images/image_perdas.jpg';
import categoria from '../../../assets/images/image_categoria_perdas.png';
import image_perda_ampliada from '../../../assets/images/image_perdas_ampliada.jpg'
import classes from './SectionBlog.module.scss';
import { HashLink as Link } from 'react-router-hash-link';
import Leia from '../SobreValid/SectionLeia/Leia';


function SectionBlog() {
    const blogLink = <Link to='/blog'>Blog</Link>;

    return (
    <div>
        <h1 className={classes.titulo}>LEIA TAMBÉM</h1>
        <div className={classes.container}>
                <CardBlog
                image={image_abrappe}
                 altText="imagem_forum_abrappe"
                 title="Fórum Abrappe de Prevenção de Perdas 2023"
                 description="Veja os principais pontos do Fórum Abrappe de Prevenção de Perdas 2023"
                 anchor='/blog'
                 />

                <CardBlog
                image={image_perdas}
                 altText="imagem_praticas_no_varejo"
                 title="Práticas Existentes no Varejo para Reduzir as Perdas por Validade Vencida."
                 description="Veja quais são as práticas eficientes para reduzir as perdas no varejo."
                 anchor='/blog'
                 />

                 <CardBlog
                image={categoria}
                 altText="imagem_analise_categoria"
                 title="Você faz a Análise por Categoria das Perdas por Validade?"
                description="Entenda o porquê de separar categorias de perda por validade e os principais pontos de atenção para gestao"
                anchor='/blog'
                />

               <CardBlog
               image={image_perda_ampliada}
                altText="imagem_compras"
                title="Oque é Perda Ampliada, e porque ela é o proximo passo para prevençaõ de perdas estratégica"
                description="Saiba oque é perda ampliada, entenda como ela está relacionada a preveção de perdas"
                anchor='/blog'    
                />            
       </div>
    </div>
    );
}

export default SectionBlog;