import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import downArrow from '../../assets/icons/arrow_down.svg';
import blogIcon from '../../assets/icons/descriptio.svg';
import personIcon from '../../assets/icons/manage_accounts.svg';
import campaignIcon from '../../assets/icons/campaign.svg';
import videoIcon from '../../assets/icons/videocam.svg';
import tagIcon from '../../assets/icons/tag.svg';
import classes from './SideMenu.module.scss';


const SideMenu = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    
    const usuario = JSON.parse(sessionStorage.getItem('usuario'));
    const handleOpen = () => setOpen(!open);
    
    const handleLogout = () => {
        sessionStorage.removeItem('usuario');
        navigate('/admin/login');
    };

    return (
        <aside className={classes.menu}>
            <div className={classes.perfil}>
                <h2 className={classes.perfil__funcao}>{usuario.funcao}</h2>

                <img
                    className={classes.perfil__img}
                    src={process.env.REACT_APP_BASE_IMAGE + 'usuarios/' + usuario.url_perfil}
                    alt="imagem de perfil"
                />

                <div className={classes.dropdown}>
                    <button className={classes.dropdown__btn} onClick={handleOpen}>
                        {usuario.nome} <img src={downArrow} />
                    </button>
                    {open ? (
                        <ul className={classes.dropdown__menu}>
                            <li>
                                <button
                                    onClick={() => {
                                        navigate('/admin/profile');
                                        setOpen(false);
                                    }}
                                >
                                    Perfil
                                </button>
                            </li>

                            <li>
                                <button onClick={handleLogout}>Sair</button>
                            </li>
                        </ul>
                    ) : null}
                </div>
            </div>

            <nav className={classes.navbar}>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? classes.navbar__link + ' ' + classes.navbar__active : classes.navbar__link
                    }
                    to="/admin/postagens"
                >
                    <img src={blogIcon} /> Postagens
                </NavLink>

                <NavLink
                    className={({ isActive }) =>
                        isActive ? classes.navbar__link + ' ' + classes.navbar__active : classes.navbar__link
                    }
                    to="/admin/propagandas"
                >
                    <img src={campaignIcon} /> Propagandas
                </NavLink>

                <NavLink
                    className={({ isActive }) =>
                        isActive ? classes.navbar__link + ' ' + classes.navbar__active : classes.navbar__link
                    }
                    to="/admin/videos"
                >
                    <img src={videoIcon} /> Videos
                </NavLink>

                <NavLink
                    className={({ isActive }) =>
                        isActive ? classes.navbar__link + ' ' + classes.navbar__active : classes.navbar__link
                    }
                    to="/admin/tags"
                >
                    <img src={tagIcon} /> Tags
                </NavLink>

                {usuario.is_admin === 1 && (
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? classes.navbar__link + ' ' + classes.navbar__active : classes.navbar__link
                        }
                        to="/admin/usuarios"
                    >
                        <img src={personIcon} /> Usuários
                    </NavLink>
                )}
            </nav>
        </aside>
    );
};

export default SideMenu;
