import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import classes from '../Form.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';

const NovaTag = () => {
    const [nome, setNome] = useState('');

    // Validações
    const [validacaoNome, setValidacaoNome] = useState({ valido: true, mensagem: '' });

    const navigate = useNavigate();

    const { token } = JSON.parse(sessionStorage.getItem('usuario'));

    const config = {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    };

    const submitFormHandler = (event) => {
        event.preventDefault();

        if (!formularioValido()) return false;

        const formData = new FormData();

        formData.append('nome', nome);

        API.post(`admin/tags`, formData, config).then((response) => {
            navigate('/admin/tags');
        });
    };

    const formularioValido = () => {
        if (!nomeValido()) return false;

        return true;
    };

    const nomeValido = () => {
        if (nome.trim() == '') {
            setValidacaoNome({ valido: false, mensagem: 'O Nome não pode estar vazio.' });
        } else if (nome.trim().length < 3) {
            setValidacaoNome({ valido: false, mensagem: 'O Nome não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoNome({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    return (
        <div className={classes.formulario}>
            <Title title="Nova Tag" />

            <form className={classes.formulario__form} onSubmit={submitFormHandler}>
                <div className={classes.formulario__two_columns}>
                    <div className={classes.formulario__card}>
                        <label htmlFor="nome">Nome:</label>
                        <input
                            className={!validacaoNome.valido ? classes.formulario__card_invalid : ''}
                            type="text"
                            name="nome"
                            id="nome"
                            onChange={(e) => setNome(e.target.value)}
                        />
                        {!validacaoNome.valido && <small>{validacaoNome.mensagem}</small>}
                    </div>
                </div>

                <button>Criar</button>
            </form>
        </div>
    );
};

export default NovaTag;
