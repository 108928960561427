import React from 'react';
import CardBlog from './SectionBlogCard';
import image_abrappe from '../../../assets/images/image_forum_abrappe.jpg';
import image_perdas from '../../../assets/images/image_perdas.jpg';
import image_categoria from  '../../../assets/images/image_categoria_perdas.png';
import image_perda_ampliada from '../../../assets/images/image_perda_ampliada.png';
import classes from './SectionBlog.module.scss';



const SectionBlog=() => {
    return (
    <div>
        <div className={classes.container}>
            <div className={classes.titulo}>
                    <h1>LEIA TAMBÉM</h1>
            </div>

            <div className={classes.cardGrid}>
                <CardBlog
                image={image_abrappe}
                 altText="imagem_forum_abrappe"
                 title="Fórum Abrappe de Prevenção de Perdas 2023"
                 description="Veja os principais pontos do Fórum Abrappe de Prevenção de Perdas 2023"
                 anchor='/blog'
                 />

                <CardBlog
                image={image_perdas}
                 altText="imagem_praticas_no_varejo"
                 title="Práticas Existentes no Varejo para Reduzir as Perdas por Validade Vencida."
                 description="Veja quais são as práticas eficientes para reduzir as perdas no varejo."
                 anchor='/blog'
                 />

                 <CardBlog
                image={image_categoria}
                 altText="imagem_analise_categoria"
                 title="Você faz a Análise por Categoria das Perdas por Validade?"
                description="Entenda o porquê de separar categorias de perda por validade e os principais pontos de atenção para gestao"
                anchor='/blog'
                />

               <CardBlog
               image={image_perda_ampliada}
                altText="imagem_compras"
                title="Oque é Perda Ampliada, e porque ela é o proximo passo para prevençaõ de perdas estratégica"
                description="Saiba oque é perda ampliada, entenda como ela está relacionada a preveção de perdas"
                anchor='/blog'    
                />            
        </div>
       </div>
    </div>
    );
}

export default SectionBlog;