import React, { useState, useRef } from 'react';
import classes from './BuildRota.module.scss';
import VideoSobre from './MainVideo';
import FormularioRota from './FormularioRota';
import HeadlineComponent from '../layout/HeadlineComponent';
import animationRota from '../../assets/images/desktop/animationRota.png';
import RotaNaPratica from '../rota/RotaNaPratica/index';

const HomePageRota = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;
    const formRef = useRef(null);

    const scrollToForm = () => {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    if (!isMobilePage) {
        return (
            <>
                <HeadlineComponent
                    title="Sistema Rota"
                    subtitle="Nossa solução para inventários rotativos"
                    description="Revolucione a maneira de controlar seu estoque"
                    image={animationRota}
                    ableBackground
                    ableButton
                />

                <div className={classes.container}>
                    <div>
                        <h1 className={classes.titulo}>
                            Facilite o processo de acuracidade de estoque na sua operação
                        </h1>
                        <h4 className={classes.conteudo}>
                            Manter o estoque sistêmico e o estoque físico o mais próximos possíveis é um desafio e tanto
                            para o varejo.
                        </h4>
                        <h4 className={classes.conteudo}>
                            Pensando nisso, desenvolvemos o ROTA, nosso sistema de inventário rotativo que utiliza dados
                            históricos e análises estatísticas para direcionar os produtos com maior probabilidade de
                            divergência em cada loja da rede.
                        </h4>
                        <h4 className={classes.conteudo}>
                            Quer saber mais sobre a nossa solução? Solicite uma apresentação comercial para saber como
                            podemos lhe ajudar! Basta preencher o formulário desta página.
                        </h4>
                    </div>
                    <div>
                        <FormularioRota ref={formRef} />
                    </div>
                </div>
                <div>
                    <RotaNaPratica onScrollToForm={scrollToForm} />
                </div>
            </>
        );
    } else {
        return (
            <div className={classes.headline}>
                <div className={classes.headline__text}>
                    <h1>Sistema Rota</h1>
                    <h4>
                        Você sabe a{' '}
                        <span className={classes.destaque_texto}>
                            importância da acuracidade do estoque, tendo o estoque
                        </span>{' '}
                        sistêmico e o estoque físico o mais próximos possíveis. Mas{' '}
                        <span className={classes.destaque_texto}>
                            manter essa informação atualizada é um dos grandes desafios
                        </span>{' '}
                        do Varejo.
                        <br />
                        <br></br>
                        Pensando nisso, desenvolvemos o{' '}
                        <span className={classes.destaque_texto}>ROTA, sistema de inventário rotativo</span> que utiliza
                        dados históricos e análises estatísticas para direcionar os produtos com maior probabilidade de
                        divergência em cada Loja da Rede.
                    </h4>
                </div>
                <h5>
                    <VideoSobre />
                </h5>
                <h6>
                    <FormularioRota />
                </h6>
            </div>
        );
    }
};

export default HomePageRota;
