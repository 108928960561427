import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import classes from '../Form.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';

const NovaPropaganda = () => {
    const [titulo, setTitulo] = useState('');
    const [url, setUrl] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    // Validações
    const [validacaoTitulo, setValidacaoTitulo] = useState({ valido: true, mensagem: '' });
    const [validacaoUrl, setValidacaoUrl] = useState({ valido: true, mensagem: '' });
    const [validacaoImagem, setValidacaoImagem] = useState({ valido: true, mensagem: '' });

    const navigate = useNavigate();

    const { token } = JSON.parse(sessionStorage.getItem('usuario'));

    const config = {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    };

    const submitFormHandler = (event) => {
        event.preventDefault();

        if (!formularioValido()) return false;

        const formData = new FormData();

        formData.append('image', selectedFile);
        formData.append('titulo', titulo);
        formData.append('url', url);

        API.post(`admin/propagandas`, formData, config).then((response) => {
            navigate('/admin/propagandas');
        });
    };

    const formularioValido = () => {
        if (!tituloValido() | !imagemValido() | !urlValido()) return false;

        return true;
    };

    const tituloValido = () => {
        if (titulo.trim() == '') {
            setValidacaoTitulo({ valido: false, mensagem: 'O título não pode estar vazio.' });
        } else if (titulo.trim().length < 3) {
            setValidacaoTitulo({ valido: false, mensagem: 'O título não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoTitulo({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const imagemValido = () => {
        if (!selectedFile) {
            setValidacaoImagem({ valido: false, mensagem: 'É preciso selecionar uma imagem.' });
        } else {
            setValidacaoImagem({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const urlValido = () => {
        if (url.trim() == '') {
            setValidacaoUrl({ valido: false, mensagem: 'A URL não pode estar vazio.' });
        } else if (url.trim().length < 3) {
            setValidacaoUrl({ valido: false, mensagem: 'A URL não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoUrl({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    return (
        <div className={classes.formulario}>
            <Title title="Nova Propaganda" />

            <form className={classes.formulario__form} onSubmit={submitFormHandler}>
                <div className={classes.formulario__two_columns}>
                    <div className={classes.formulario__card}>
                        <label htmlFor="titulo">Título:</label>
                        <input
                            className={!validacaoTitulo.valido ? classes.formulario__card_invalid : ''}
                            type="text"
                            name="titulo"
                            id="titulo"
                            onChange={(e) => setTitulo(e.target.value)}
                        />
                        {!validacaoTitulo.valido && <small>{validacaoTitulo.mensagem}</small>}
                    </div>

                    <div className={classes.formulario__card}>
                        <label htmlFor="url">URL:</label>
                        <input
                            className={!validacaoUrl.valido ? classes.formulario__card_invalid : ''}
                            type="url"
                            name="url"
                            id="url"
                            onChange={(e) => setUrl(e.target.value)}
                        />
                        {!validacaoUrl.valido && <small>{validacaoUrl.mensagem}</small>}
                    </div>
                </div>

                <div className={classes.formulario__imagem}>
                    <label htmlFor="imagem">
                        Imagem da Propaganda<small>(minimo: 200x200px - máximo:400x400px)</small>:
                    </label>
                    <input
                        type="file"
                        id="imagem"
                        accept="image/*"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    {!validacaoImagem.valido && <small>{validacaoImagem.mensagem}</small>}
                </div>

                <button>Criar</button>
            </form>
        </div>
    );
};

export default NovaPropaganda;
