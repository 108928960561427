import React from 'react';
import classes from './Leia.module.scss';

const Leia = () => {
    return (
        <div className={classes.section}>
            <h1 className={classes.titulo}>Sobre o VALID</h1>

            <p className={classes.paragrafos}>
                O VALID identifica e estratifica alguns produtos que são realmente necessitam de atenção e a partir do
                aplicativo, que funciona em smartphones e coletor de dados, são geradas tarefas para execução pela
                operação de loja, a partir da execução da tarefa, todas as informações são coletadas e disponibilizadas
                para gerencia de loja e áreas administrativas da rede.
            </p>
            <br></br>
            <p className={classes.paragrafos}>
                Dessa forma, o processo de "arrastão" torna-se desnecessário reduzindo o tempo e colaboradores
                necessários e com base nesses dados é possível traçar planos de ação afim de evitar a quebra em meses
                seguintes e realizar um planejamento de médio / longo prazo com o objetivo que essas quebras não
                retornem.
            </p>

            <button className={classes.button} onClick={() => window.open('https://wa.me/5544997525279', '_blank')}>
                Entre em{' '}
                <span className={classes.destaque}>
                    <strong>contato</strong>
                </span>{' '}
                conosco
            </button>
        </div>
    );
};
export default Leia;
