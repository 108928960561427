import React, { useState } from 'react';
import HomePageRota from './BuildRota';
import Inscrever from './Inscrever';
import SistemasRota from './SistemasRota';
import SobreRota from './SobreRota';
import AcuracidadeSection from './AcuracidadeSection/Acuracidade';
import BotaoInscrever from './BotaoInscrever/BotaoInscrever';
import SectionBlog from './FooterBlog/SectionBlog';

const Rota = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return (
            <>
                <HomePageRota />
                <AcuracidadeSection />
            </>
        );
    } else {
        return (
            <>
                <HomePageRota />
                <Inscrever />
                <SistemasRota />
                <SobreRota />
                <AcuracidadeSection />
                <BotaoInscrever />
                <SectionBlog />
            </>
        );
    }
};

export default Rota;
