import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../api';

import classes from './Profile.module.scss';
import Modal from '../../components/UI/Modal';
import checkIcon from '../../assets/icons/check_circle.svg';

const usuario = JSON.parse(sessionStorage.getItem('usuario'));

const Profile = () => {
    const [nome, setNome] = useState(usuario.nome);
    const [email, setEmail] = useState(usuario.email);
    const [funcao, setFuncao] = useState(usuario.funcao);
    const [biografia, setBiografia] = useState(usuario.biografia);
    const [linkedin, setLinkedin] = useState(usuario.linkedin);
    const [senha, setSenha] = useState('');
    const [confirmaSenha, setConfirmaSenha] = useState('');
    const [senhaErrada, setSenhaErrada] = useState('');
    const [showModal, setShowModal] = useState({
        isVisible: false,
        titulo: '',
        texto: '',
    });
    const [selectedFile, setSelectedFile] = useState(null);

    // Validações
    const [validacaoNome, setValidacaoNome] = useState({ valido: true, mensagem: '' });
    const [validacaoEmail, setValidacaoEmail] = useState({ valido: true, mensagem: '' });
    const [validacaoFuncao, setValidacaoFuncao] = useState({ valido: true, mensagem: '' });
    const [validacaoBiografia, setValidacaoBiografia] = useState({ valido: true, mensagem: '' });
    const [validacaoLinkedin, setValidacaoLinkedin] = useState({ valido: true, mensagem: '' });

    const config = {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${usuario.token}` },
    };

    const navigate = useNavigate();

    const submitFormHandler = (event) => {
        event.preventDefault();

        if (!formularioValido()) return false;

        const formData = new FormData();
        formData.append('_method', 'PATCH');

        formData.append('id', usuario.id_usuario);
        formData.append('image', selectedFile);
        formData.append('nome', nome);
        formData.append('email', email);
        formData.append('senha', senha);
        formData.append('funcao', funcao);
        formData.append('biografia', biografia);
        formData.append('linkedin', linkedin);

        API.post(`admin/profiles`, formData, config).then((response) => {
            setShowModal({
                isVisible: true,
                titulo: 'Sucesso ao alterar os dados',
                texto: 'Faça Login novamente para atualizar as informações!',
            });

            setTimeout(() => {
                navigate('/admin/login');
            }, 2500);
        });
    };

    const formularioValido = () => {
        if (!nomeValido() | !emailValido() | !funcaoValido() | !biografiaValido() | !linkedinValido()) return false;

        return true;
    };

    const nomeValido = () => {
        if (nome.trim() == '') {
            setValidacaoNome({ valido: false, mensagem: 'O Nome não pode estar vazio.' });
        } else if (nome.trim().length < 3) {
            setValidacaoNome({ valido: false, mensagem: 'O Nome não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoNome({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const emailValido = () => {
        if (email.trim() == '') {
            setValidacaoEmail({ valido: false, mensagem: 'O E-mail não pode estar vazio.' });
        } else if (email.trim().length < 3) {
            setValidacaoEmail({ valido: false, mensagem: 'O E-mail não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoEmail({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const funcaoValido = () => {
        if (funcao.trim() == '') {
            setValidacaoFuncao({ valido: false, mensagem: 'A Função não pode estar vazio.' });
        } else if (funcao.trim().length < 3) {
            setValidacaoFuncao({ valido: false, mensagem: 'A Função não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoFuncao({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const biografiaValido = () => {
        if (biografia.trim() == '') {
            setValidacaoBiografia({ valido: false, mensagem: 'A Biografia não pode estar vazio.' });
        } else if (biografia.trim().length < 3) {
            setValidacaoBiografia({ valido: false, mensagem: 'A Biografia não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoBiografia({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const linkedinValido = () => {
        if (linkedin.trim() == '') {
            setValidacaoLinkedin({ valido: false, mensagem: 'A Biografia não pode estar vazio.' });
        } else if (linkedin.trim().length < 3) {
            setValidacaoLinkedin({ valido: false, mensagem: 'A Biografia não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoLinkedin({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const mudaSenhaHandler = () => {
        let validacao = validaSenha();

        if (validacao != '') return false;

        const data = {
            id: usuario.id_usuario,
            senha,
        };

        API.patch(`admin/profiles/nova-senha`, data, config).then((response) => {
            setShowModal({
                isVisible: true,
                titulo: 'Senha alterada com sucesso!',
                texto: '',
            });
        });
    };

    function validaSenha() {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const passwordLength = senha.length;
        const uppercasePassword = uppercaseRegExp.test(senha);
        const lowercasePassword = lowercaseRegExp.test(senha);
        const digitsPassword = digitsRegExp.test(senha);
        const specialCharPassword = specialCharRegExp.test(senha);
        const minLengthPassword = minLengthRegExp.test(senha);

        let errMsg = '';

        if (passwordLength === 0) errMsg = 'A senha não pode estar vazia';
        else if (!uppercasePassword) errMsg = 'A senha precisa de ao menos 1 caracter maiusculo';
        else if (!lowercasePassword) errMsg = 'A senha precisa de ao menos 1 caracter minusculo';
        else if (!digitsPassword) errMsg = 'A senha precisa de ao menos 1 digito ';
        else if (!specialCharPassword) errMsg = 'A senha precisa de ao menos 1 caracter especial';
        else if (!minLengthPassword) errMsg = 'A senha precisa ter no mínimo 8 caracteres';
        else errMsg = '';

        setSenhaErrada(errMsg);

        if (senha !== confirmaSenha) {
            setSenhaErrada('A confirmação de senha não esta igual');

            errMsg = 'A confirmação de senha não esta igual';
        }

        return errMsg;
    }

    const changeModalHandler = () => {
        setShowModal({
            isVisible: false,
            titulo: '',
            texto: '',
        });
    };

    return (
        <div className={classes.profile}>
            <form className={classes.profile__form} onSubmit={submitFormHandler}>
                <h2>Editar Perfil</h2>
                <div className={classes.profile__two_columns}>
                    <div className={classes.profile__card}>
                        <label htmlFor="nome">Nome:</label>
                        <input
                            className={!validacaoNome.valido ? classes.profile__card_invalid : ''}
                            type="text"
                            name="nome"
                            id="nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />
                        {!validacaoNome.valido && <small>{validacaoNome.mensagem}</small>}
                    </div>
                    <div className={classes.profile__card}>
                        <label htmlFor="funcao">Função:</label>
                        <input
                            className={!validacaoFuncao.valido ? classes.profile__card_invalid : ''}
                            type="text"
                            name="funcao"
                            id="funcao"
                            value={funcao}
                            onChange={(e) => setFuncao(e.target.value)}
                        />
                        {!validacaoFuncao.valido && <small>{validacaoFuncao.mensagem}</small>}
                    </div>
                </div>

                <div className={classes.profile__card}>
                    <label htmlFor="email">E-mail:</label>
                    <input
                        className={!validacaoEmail.valido ? classes.profile__card_invalid : ''}
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!validacaoEmail.valido && <small>{validacaoEmail.mensagem}</small>}
                </div>

                <div className={classes.profile__card}>
                    <label htmlFor="linkedin">URL do Linkedin:</label>
                    <input
                        className={!validacaoLinkedin.valido ? classes.profile__card_invalid : ''}
                        type="url"
                        name="linkedin"
                        id="linkedin"
                        value={linkedin}
                        onChange={(e) => setLinkedin(e.target.value)}
                    />
                    {!validacaoLinkedin.valido && <small>{validacaoLinkedin.mensagem}</small>}
                </div>

                <div className={classes.profile__card}>
                    <label htmlFor="biografia">Biografia:</label>
                    <textarea
                        className={!validacaoBiografia.valido ? classes.profile__card_invalid : ''}
                        name="biografia"
                        id="biografia"
                        rows="8"
                        value={biografia}
                        onChange={(e) => setBiografia(e.target.value)}
                    ></textarea>
                    {!validacaoBiografia.valido && <small>{validacaoBiografia.mensagem}</small>}
                </div>

                <div className={classes.profile__imagem}>
                    <label htmlFor="imagem">
                        Imagem de Perfil<small>(minimo: 140x140px - máximo:800x800px)</small>:
                    </label>
                    <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                </div>

                <button>Editar</button>
            </form>

            <div className={classes.profile__destaque}>
                <h2>Alterar Senha</h2>

                <div className={classes.profile__card}>
                    <label htmlFor="senha">Nova Senha:</label>
                    <input type="password" name="senha" id="senha" onChange={(e) => setSenha(e.target.value)} />
                </div>

                <div className={classes.profile__card}>
                    <label htmlFor="confirmeSenha">Confirmação Senha:</label>
                    <input
                        type="password"
                        name="confirmeSenha"
                        id="confirmeSenha"
                        onChange={(e) => setConfirmaSenha(e.target.value)}
                    />
                </div>
                <small>{senhaErrada}</small>

                <button onClick={mudaSenhaHandler}>Alterar</button>
            </div>

            {showModal.isVisible && (
                <Modal onClose={changeModalHandler}>
                    <div className={classes.mensagem}>
                        <img src={checkIcon} alt="check icon" />
                        <h2>{showModal.titulo}</h2>
                        {showModal.texto != '' && <p>{showModal.texto}</p>}
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Profile;
