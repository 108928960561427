import React, { useState } from 'react';

import classes from './style.module.scss';
import logoAWS from '../../../assets/images/tecnologia/aws.png';
import logoKNIME from '../../../assets/images/tecnologia/knime.png';

import logoVerona from '../../../assets/images/logo_verona.svg';
import logoCSD from '../../../assets/images/new_amigao.jpg';
import logoLinx from '../../../assets/images/tecnologia/linx.png';
import logoTotvs from '../../../assets/images/tecnologia/totvs.png';
import logoSap from '../../../assets/images/tecnologia/SAP.png';

import logoSupermaxi from '../../../assets/images/supermaxi.png';
import logoPanvel from '../../../assets/images/new_logo_panvel.png';
import logoCondor from '../../../assets/images/new_logo_condor.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FiChevronRight } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';

const Index = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    function PrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                id={classes.prev_arrow}
                style={{
                    ...style,
                    color: 'black',
                    zIndex: 1,
                    fontSize: '90px',
                    alignItems: 'center',
                    width: '100px',
                    height: '70%',
                    cursor: 'pointer',
                }}
                onClick={onClick}
            >
                <FiChevronLeft size={65} />
            </div>
        );
    }

    function NextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                id={classes.next_arrow}
                style={{
                    ...style,
                    display: 'block',
                    alignItems: 'center',
                    background: 'none',
                    zIndex: 1,
                    fontSize: '90px',
                    width: '60px',
                    marginTop: '30px',
                    height: '90%',
                }}
                onClick={onClick}
            >
                <FiChevronRight id={classes.rightArrow} size={65} />
            </div>
        );
    }

    var settingDesktop = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        className: classes.clientes__items__desktop,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,

        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        className: classes.clientes__items,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    if (!isMobilePage) {
        return (
            <section id="clientes" className={classes.clientes__desktop}>
                <div className={classes.titulo_thispage__desktop}>
                    <p>TECNOLOGIAS PRESENTES NAS NOSSAS SOLUÇÕES</p>
                </div>
                <div className={classes.container_slide}>
                    <Slider {...settingDesktop}>
                        <div className={classes.logo_cliente__desktop}>
                            <a href="https://institucional.csdvarejo.com.br" target="_blank" rel="noopener noreferrer">
                                <img src={logoAWS} alt="logo do cliente CSD" />
                            </a>
                        </div>
                        <div className={classes.logo_cliente__desktop}>
                            <a href="https://www.condor.com.br" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={logoKNIME}
                                    alt="logo do cliente Condor"
                                    style={{ width: '65%', marginLeft: '35px' }}
                                />
                            </a>
                        </div>
                        <div className={classes.logo_cliente__desktop}>
                            <a href="https://www.jauserve.com.br" target="_blank" rel="noopener noreferrer">
                                <img src={logoLinx} alt="logo do cliente Jau" />
                            </a>
                        </div>
                        <div className={classes.logo_cliente__desktop}>
                            <a href="https://www.supermercadolopes.com.br" target="_blank" rel="noopener noreferrer">
                                <img src={logoTotvs} alt="logo do cliente Lopes" />
                            </a>
                        </div>
                        <div className={classes.logo_cliente__desktop}>
                            <a href="https://redesupermaxi.com.br/" target="_blank" rel="noopener noreferrer">
                                <img src={logoSap} alt="logo do cliente Supermaxi" />
                            </a>
                        </div>
                        <div className={classes.logo_cliente__desktop}>
                            <a href="https://institucional.csdvarejo.com.br" target="_blank" rel="noopener noreferrer">
                                <img src={logoAWS} alt="logo do cliente CSD" />
                            </a>
                        </div>
                        <div className={classes.logo_cliente__desktop}>
                            <a href="https://www.condor.com.br" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={logoKNIME}
                                    style={{ width: '65%', marginLeft: '35px' }}
                                    alt="logo do cliente Condor"
                                />
                            </a>
                        </div>
                        <div className={classes.logo_cliente__desktop}>
                            <a href="https://www.jauserve.com.br" target="_blank" rel="noopener noreferrer">
                                <img src={logoLinx} alt="logo do cliente Jau" />
                            </a>
                        </div>
                        <div className={classes.logo_cliente__desktop}>
                            <a href="https://www.supermercadolopes.com.br" target="_blank" rel="noopener noreferrer">
                                <img src={logoTotvs} alt="logo do cliente Lopes" />
                            </a>
                        </div>
                        <div className={classes.logo_cliente__desktop}>
                            <a href="https://redesupermaxi.com.br/" target="_blank" rel="noopener noreferrer">
                                <img src={logoSap} alt="logo do cliente Supermaxi" />
                            </a>
                        </div>
                    </Slider>
                </div>
            </section>
        );
    } else {
        return (
            <section id="clientes" className={classes.clientes}>
                <div className={classes.titulo_thispage}>
                    <p>
                        Redes que confiam na <span className={classes.destacado}>Minus</span>
                    </p>
                </div>

                <Slider {...settings}>
                    <div className={classes.logo_cliente}>
                        <a href="https://institucional.csdvarejo.com.br" target="_blank" rel="noopener noreferrer">
                            <img src={logoAWS} alt="logo do cliente CSD" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://www.condor.com.br" target="_blank" rel="noopener noreferrer">
                            <img src={logoKNIME} alt="logo do cliente Condor" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://www.jauserve.com.br" target="_blank" rel="noopener noreferrer">
                            <img src={logoSupermaxi} alt="logo do cliente Jau" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://www.supermercadolopes.com.br" target="_blank" rel="noopener noreferrer">
                            <img src={logoSupermaxi} alt="logo do cliente Lopes" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://redesupermaxi.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoSupermaxi} alt="logo do cliente Supermaxi" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://www.grupopanvel.com.br/pt" target="_blank" rel="noopener noreferrer">
                            <img src={logoPanvel} alt="logo do cliente Panvel" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://veronasupermercados.com.br/site" target="_blank" rel="noopener noreferrer">
                            <img src={logoVerona} alt="logo do cliente Verona" />
                        </a>
                    </div>
                </Slider>
                <div className={classes.container}>
                    <button
                        className={classes.button}
                        onClick={() =>
                            window.open(
                                'https://api.whatsapp.com/send/?phone=5544999824060&text=Ol%C3%A1%2C+vim+pelo+site+e+gostaria+de+obter+mais+informa%C3%A7%C3%B5es+sobre+os+sistemas+da+Minus&type=phone_number&app_absent=0',
                                '_blank',
                                'noopener noreferrer'
                            )
                        }
                    >
                        Fale com um consultor!
                    </button>
                </div>
            </section>
        );
    }
};

export default Index;
