import React from 'react';

import Rota from '../components/rota/Rota';

const RotaSection = () => {
    return (
        <>
            <Rota />
        </>
    );
};

export default RotaSection;
