import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from 'react-share';

import API from '../../api';

import linkdinIcon from '../../assets/icons/linkedin_autor.svg';
import classes from './BlogDetalhe.module.scss';
import PostCard from '../../components/blog/PostCard';
import Inscrever from '../../components/blog/Inscrever';

const baseImg = process.env.REACT_APP_BASE_IMAGE;

const option = {
    year: 'numeric',
    month: 'short',
};
const locale = 'pt-br';

const BlogDetalhe = () => {
    const [postagem, setPostagem] = useState([]);
    const [propagandas, setPropagandas] = useState([]);
    const [postagensMaisVisualizadas, setPostagensMaisVisualizadas] = useState([]);
    const params = useParams();

    const { postSlug } = params;

    useEffect(() => {
        API.get(`blog/mais-vistos`).then((response) => setPostagensMaisVisualizadas(response.data));
        API.get(`blog/propagandas`).then((response) => setPropagandas(response.data));
        API.get(`blog/detalhes/${postSlug}`).then((response) => {
            response.data.conteudo = Parser(response.data.conteudo);
            setPostagem(response.data);
            API.patch(`blog/visualizacoes`, { id: response.data.id });
        });
    }, [postSlug]);

    const classeNoticia = propagandas.length > 0 ? '' : classes.noticia_hide;

    return (
        <main className={classes.main}>
            <div className={classes.wrapper + ' ' + classes.noticia + ' ' + classeNoticia}>
                {postagem && (
                    <section className={classes.noticia__conteudo}>
                        <h1>{postagem.titulo}</h1>

                        <p>{postagem.descricao}</p>

                        <div className={classes.noticia__media}>
                            <div className={classes.noticia__media_icons}>
                                <FacebookShareButton
                                    url={`https://minus.eco.br/blog/${postSlug}`}
                                    children={<FacebookIcon size={32} round={true} />}
                                    quote={postagem.descricao}
                                />
                                <TwitterShareButton
                                    url={`https://minus.eco.br/blog/${postSlug}`}
                                    children={<TwitterIcon size={32} round={true} />}
                                    title={postagem.titulo}
                                />
                                <WhatsappShareButton
                                    url={`https://minus.eco.br/blog/${postSlug}`}
                                    children={<WhatsappIcon size={32} round={true} />}
                                />
                                <LinkedinShareButton
                                    url={`https://minus.eco.br/blog/${postSlug}`}
                                    children={<LinkedinIcon size={32} round={true} />}
                                    source="Minus Tecnologia"
                                    title={postagem.titulo}
                                    summary={postagem.descricao}
                                />
                            </div>

                            <div>
                                <small>
                                    {new Date(postagem.data_publicacao)
                                        .toLocaleDateString(locale, option)
                                        .replace('. de ', '/')}{' '}
                                    | Leitura: {postagem.tempo_leitura}min
                                </small>

                                <small>{postagem.tag_nome}</small>
                            </div>
                        </div>

                        <img
                            src={`${baseImg}/capa/${postagem.imagem_capa}`}
                            className={classes.noticia__capa}
                            alt="imagem do post"
                        />

                        <div className="ql-editor" style={{ padding: 0, height: 'inherit' }}>
                            {postagem.conteudo}
                        </div>

                        <div className={classes.autor}>
                            <img
                                src={`${baseImg}/usuarios/${postagem.url_perfil}`}
                                className={classes.autor__img}
                                alt="imagem do autor"
                            />

                            <div>
                                <h4>{postagem.nome}</h4>
                                <p>{postagem.biografia}</p>
                                <Link to={postagem.linkedin} target="_blank">
                                    <img src={linkdinIcon} className={classes.autor__icon} alt="linkedin icon" />
                                </Link>
                            </div>
                        </div>
                    </section>
                )}

                {propagandas.length > 0 && (
                    <aside>
                        {propagandas.map((item) => (
                            <Link to={item.url} key={item.id} target="_blank" rel="noopener noreferrer">
                                <img src={`${baseImg}/propagandas/${item.filename}`} alt={item.titulo} />
                            </Link>
                        ))}
                    </aside>
                )}
            </div>

            <Inscrever />

            <section className={classes.wrapper + ' ' + classes.relacionados}>
                <h2>Leia Também</h2>

                <div className={classes.relacionados__card}>
                    {postagensMaisVisualizadas.map((post) => (
                        <PostCard key={post.id} {...post} />
                    ))}
                </div>
            </section>
        </main>
    );
};

export default BlogDetalhe;
