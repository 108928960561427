import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import API from '../../../api';

import classes from '../Form.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';

const NovaPostagem = () => {
    const [conteudo, setConteudo] = useState('');
    const [titulo, setTitulo] = useState('');
    const [tempoLeitura, setTempoLeitura] = useState('');
    const [descricao, setDescricao] = useState('');
    const [prioridade, setPrioridade] = useState(0);
    const [urlSlug, setUrlSlug] = useState('');
    const [notificacao, setNotificacao] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [tags, setTags] = useState(null);
    const [checkedTags, setCheckedTags] = useState([]);

    // Validações
    const [validacaoTitulo, setValidacaoTitulo] = useState({ valido: true, mensagem: '' });
    const [validacaoSlug, setValidacaoSlug] = useState({ valido: true, mensagem: '' });
    const [validacaoTempo, setValidacaoTempo] = useState({ valido: true, mensagem: '' });
    const [validacaoDescricao, setValidacaoDescricao] = useState({ valido: true, mensagem: '' });
    const [validacaoImagem, setValidacaoImagem] = useState({ valido: true, mensagem: '' });
    const [validacaoConteudo, setValidacaoConteudo] = useState({ valido: true, mensagem: '' });

    const navigate = useNavigate();
    const quillRef = useRef();

    const { token, id_usuario } = JSON.parse(sessionStorage.getItem('usuario'));

    const config = {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    };

    const changeTituloHandler = (event) => setTitulo(event.target.value);
    const changeTempoLeituraHandler = (event) => setTempoLeitura(event.target.value);
    const changeDescricaoHandler = (event) => setDescricao(event.target.value);
    const changePrioridadeHandler = (event) => setPrioridade(event.target.value);

    const handleCheck = (event) => {
        let updatedList = [...checkedTags];
        if (event.target.checked) {
            updatedList = [...checkedTags, event.target.value];
        } else {
            updatedList.splice(checkedTags.indexOf(event.target.value), 1);
        }
        setCheckedTags(updatedList);
    };

    useEffect(() => {
        const { token } = JSON.parse(sessionStorage.getItem('usuario'));

        API.get(`admin/tags`, { headers: { Authorization: `Bearer ${token}` } }).then((response) => {
            setTags(response.data);
        });
    }, []);

    const submitFormHandler = (event) => {
        event.preventDefault();

        const formData = new FormData();

        if (!formularioValido()) return false;

        formData.append('image', selectedFile);
        formData.append('id_usuario', id_usuario);
        formData.append('titulo', titulo);
        formData.append('tempoLeitura', tempoLeitura);
        formData.append('descricao', descricao);
        formData.append('conteudo', conteudo);
        formData.append('prioridade', prioridade);
        formData.append('url_slug', urlSlug);
        formData.append('tags', checkedTags);
        formData.append('enviar_notificacao', notificacao);

        API.post(`admin/blog`, formData, config).then(() => navigate('/admin/postagens'));
    };

    const formularioValido = () => {
        if (!tituloValido() | !slugValido() | !tempoValido() | !descricaoValido() | !imagemValida() | !conteudoValido())
            return false;

        return true;
    };

    const tituloValido = () => {
        if (titulo.trim() == '') {
            setValidacaoTitulo({ valido: false, mensagem: 'O título não pode estar vazio.' });
        } else if (titulo.trim().length < 3) {
            setValidacaoTitulo({ valido: false, mensagem: 'O título não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoTitulo({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const slugValido = () => {
        if (urlSlug.trim() == '') {
            setValidacaoSlug({ valido: false, mensagem: 'O Slug da URL não pode estar vazio.' });
        } else if (urlSlug.trim().length < 3) {
            setValidacaoSlug({ valido: false, mensagem: 'O Slug da URL não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoSlug({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const descricaoValido = () => {
        if (descricao.trim() == '') {
            setValidacaoDescricao({ valido: false, mensagem: 'A Descrição não pode estar vazio.' });
        } else if (descricao.trim().length < 3) {
            setValidacaoDescricao({ valido: false, mensagem: 'A Descrição não pode ter menos de 3 caracteres.' });
        } else {
            setValidacaoDescricao({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const tempoValido = () => {
        if (tempoLeitura.trim() == '') {
            setValidacaoTempo({ valido: false, mensagem: 'O Tempo de leitura não pode estar vazio.' });
        } else {
            setValidacaoTempo({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const imagemValida = () => {
        if (!selectedFile) {
            setValidacaoImagem({ valido: false, mensagem: 'É preciso selecionar uma imagem para a capa.' });
        } else {
            setValidacaoImagem({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const conteudoValido = () => {
        if (conteudo.trim() == '') {
            setValidacaoConteudo({ valido: false, mensagem: 'O conteudo não pode estar vazio.' });
        } else {
            setValidacaoConteudo({ valido: true, mensagem: '' });
            return true;
        }

        return false;
    };

    const imageHandler = (e) => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            const formData = new FormData();

            formData.append('image', file);

            const res = await API.post(`admin/blog/imagem`, formData, config);

            let url = res.data.url_imagem;
            editor.insertEmbed(editor.getSelection().index, 'image', process.env.REACT_APP_BASE_IMAGE + 'quill/' + url);
        };
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ color: [] }, { background: [] }],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
                    ['image', 'link'],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        }),
        []
    );

    return (
        <div className={classes.formulario}>
            <Title title="Novo Post" />

            <form className={classes.formulario__form} onSubmit={submitFormHandler}>
                <div className={classes.formulario__card}>
                    <label htmlFor="titulo">
                        Título<small>*</small>:
                    </label>
                    <input
                        className={!validacaoTitulo.valido ? classes.formulario__card_invalid : ''}
                        type="text"
                        name="titulo"
                        id="titulo"
                        onChange={changeTituloHandler}
                    />
                    {!validacaoTitulo.valido && <small>{validacaoTitulo.mensagem}</small>}
                </div>

                <div className={classes.formulario__card}>
                    <label htmlFor="slug">
                        Slug da URL<small>*(sem acentos ou caracteres especiais)</small>:
                    </label>
                    <input
                        className={!validacaoSlug.valido ? classes.formulario__card_invalid : ''}
                        type="text"
                        name="slug"
                        id="slug"
                        onChange={(e) => setUrlSlug(e.target.value)}
                    />
                    {!validacaoSlug.valido && <small>{validacaoSlug.mensagem}</small>}
                </div>

                <div className={classes.formulario__two_columns}>
                    <div className={classes.formulario__card}>
                        <label htmlFor="tempoLeitura">
                            Tempo de Leitura<small>*</small>:
                        </label>
                        <input
                            className={!validacaoTempo.valido ? classes.formulario__card_invalid : ''}
                            type="number"
                            name="tempoLeitura"
                            id="tempoLeitura"
                            min="0"
                            max="99"
                            onChange={changeTempoLeituraHandler}
                        />
                        {!validacaoTempo.valido && <small>{validacaoTempo.mensagem}</small>}
                    </div>

                    <div className={classes.formulario__card}>
                        <label htmlFor="prioridade">Prioridade:</label>

                        <select name="prioridade" id="prioridade" onChange={changePrioridadeHandler}>
                            <option value="0">Sem Prioridade</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                    </div>
                </div>

                <div className={classes.formulario__card}>
                    <label htmlFor="descricao">
                        Breve Descrição<small>*</small>:
                    </label>
                    <textarea
                        className={!validacaoDescricao.valido ? classes.formulario__card_invalid : ''}
                        name="descricao"
                        id="descricao"
                        rows="8"
                        onChange={changeDescricaoHandler}
                    ></textarea>
                    {!validacaoDescricao.valido && <small>{validacaoDescricao.mensagem}</small>}
                </div>

                <div className={classes.formulario__imagem}>
                    <label htmlFor="imagem">
                        Imagem de Capa<small>*</small>:
                    </label>
                    <input type="file" accept="image/*" onChange={(e) => setSelectedFile(e.target.files[0])} />
                    {!validacaoImagem.valido && <small>{validacaoImagem.mensagem}</small>}
                </div>

                <div className={classes.formulario__conteudo}>
                    <label htmlFor="">
                        Conteúdo do Post<small>*</small>:
                    </label>
                    <ReactQuill theme="snow" ref={quillRef} value={conteudo} onChange={setConteudo} modules={modules} />
                    {!validacaoConteudo.valido && <small>{validacaoConteudo.mensagem}</small>}
                </div>

                <div className={classes.formulario__card}>
                    <label htmlFor="tags">
                        Tags<small>*</small>:
                    </label>
                    <div className={classes.formulario__tags}>
                        {tags &&
                            tags.map((item, index) => (
                                <label key={item.id} className={classes.formulario__tags_label}>
                                    <input value={item.id} type="checkbox" name="tags" onChange={handleCheck} />
                                    <span>{item.nome}</span>
                                </label>
                            ))}
                    </div>
                </div>

                <div className={classes.formulario__radio}>
                    <label>
                        Enviar Notificação<small>*</small>:
                    </label>

                    <div className={classes.formulario__radio_grid}>
                        <div className={classes.formulario__radio_input}>
                            <input
                                type="radio"
                                name="notificacao"
                                id="enviarNotificacao"
                                onChange={() => setNotificacao(true)}
                            />
                            <label htmlFor="enviarNotificacao">Sim</label>
                        </div>

                        <div className={classes.formulario__radio_input}>
                            <input
                                type="radio"
                                name="notificacao"
                                id="naoEnviarNotificacao"
                                onChange={() => setNotificacao(false)}
                            />
                            <label htmlFor="naoEnviarNotificacao">Não</label>
                        </div>
                    </div>
                </div>

                <button>Postar</button>
            </form>
        </div>
    );
};

export default NovaPostagem;
