import classes from './SobreRota.module.scss';
import luz from  '../../assets/images/image_luz_rota.png';
import grafico from '../../assets/images/image_grafico_rota.png';
import agilidade from '../../assets/images/image_agilidade_rota.png';
import estrategica from '../../assets/images/image_estrategica_rota.png';
import CardImageLeft from './CardImageLeft';
import CardImageRight from './CardImageRight';


const SobreRota= () => {

    return (
        <div className={classes.cards}>
            <div className={classes.containerRight}>
                <CardImageRight
                image={luz}
                altText="Texto alternativo da imagem"
                title="Alertas e Direcionamento para Lojas:"
                description1="Alertas dos produtos com maior probabilidade de divergência de estoque (Físico x Sistêmico)"
                description2="Coleta de informações através do Aplicativo Mobile que pode ser instalado em Celulares e Coletores de Dados."
                description3="Aplicativo com operação Online e Offline."/>
            </div>
            <div className={classes.containerLeft}>
                <CardImageLeft
                image={grafico}
                altText="Texto alternativo da imagem"
                title="Dashboards Personalizados:"
                description1="Tenha as informações de divergências e estoque atualizadas"
                description2="Acompanhe os indicadores da sua operação"
                description3="Monitore a execução de atividades de cada uma de suas lojas"/>
             </div>
            <div className={classes.containerRight}>
                <CardImageRight
                image={agilidade}
                altText="Texto alternativo da imagem"
                title="Alertas e Direcionamento para Lojas:"
                description1="Alertas dos produtos com maior probabilidade de divergência de estoque (Físico x Sistêmico)"
                description2="Coleta de informações através do Aplicativo Mobile que pode ser instalado em Celulares e Coletores de Dados."
                description3="Aplicativo com operação Online e Offline."/>
            </div>
            <div className={classes.containerLeft}>
                <CardImageLeft
                image={estrategica}
                altText="Texto alternativo da imagem"
                title="Atuação Estratégica:"
                description1="Tenha as informações de divergências e estoque atualizadas"
                description2="Acompanhe os indicadores da sua operação"
                description3="Monitore a execução de atividades de cada uma de suas lojas"/>
             </div>
         </div>
    );  
}



export  default SobreRota;
