import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import plusIcon from '../../../assets/icons/add.svg';
import classes from '../Lista.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';
import Usuario from './Usuario';

const ListaUsuarios = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [usuarioDelete, setUsuarioDelete] = useState(false);
    const navigate = useNavigate();

    const { token } = JSON.parse(sessionStorage.getItem('usuario'));

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    useEffect(() => {
        API.get(`admin/usuarios`, config).then((response) => {
            setUsuarios(response.data);
        });
    }, []);

    const newUserHandler = () => navigate('/admin/usuarios/create');
    const cancelDeleteHandler = () => setUsuarioDelete(false);

    const modalConfirmacao = (user_id) => {
        const user = usuarios.find((element) => {
            return element.id == user_id;
        });

        setUsuarioDelete(user);
    };

    const excluirUsuarioHandler = () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            data: { id: usuarioDelete.id },
        };

        API.delete(`admin/usuarios`, config).then(() => {
            const usuariosFiltrados = usuarios.filter((element) => {
                return element.id != usuarioDelete.id;
            });

            setUsuarios(usuariosFiltrados);
            setUsuarioDelete(false);
        });
    };

    return (
        <div className={classes.lista}>
            <div className={classes.lista__header}>
                <Title title="Usuários" />

                <button onClick={newUserHandler}>
                    <img src={plusIcon} alt="plus icon" /> Adicionar
                </button>
            </div>

            <ul className={classes.lista__itens}>
                {usuarios.map((user) => (
                    <Usuario key={user.id} {...user} modalConfirmacao={modalConfirmacao} />
                ))}
            </ul>

            {usuarioDelete && (
                <div className={classes.lista__confirmacao}>
                    <p>
                        Tem certeza que deseja excluir o usuario: <strong>{usuarioDelete.nome}</strong>?
                    </p>

                    <div className={classes.lista__confirmacao_buttons}>
                        <button onClick={cancelDeleteHandler}>Cancelar</button>
                        <button onClick={excluirUsuarioHandler}>Excluir</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListaUsuarios;
