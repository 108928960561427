import React, { useState } from 'react';

import classes from './Headline.module.scss';
import VideoSobre from '../MainVideo';
import ButtonConheca from '../ButtonConheca/index';
import MercadoIcone from '../../../assets/images/desktop/Mercado.png';
import FarmaciaIcone from '../../../assets/images/desktop/Farmácia.png';
import CalendarIcon from '../../../assets/images/desktop/doze-calendar.png';
import SetasIcon from '../../../assets/images/desktop/duas-setas-circulares.png';
import HeadLineSection01 from '../../layout/HeadlineSection01/index';

const Headline = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return (
            <>
                <HeadLineSection01 />
                <div className={classes.ctn_title}>
                    <p>Torne sua Gestão de Perdas mais Eficiente</p>
                </div>
                <div className={classes.headline_desktop}>
                    <div className={classes.headline__video}>
                        <VideoSobre />
                    </div>
                    <div className={classes.headline_desktop__text__desktop}>
                        <p>
                            Desenvolvemos soluções que apoiam a gestão desde a entrada do produto na loja até planos de
                            ação para reduzir perdas, padronizar processos e aumentar a eficiência da sua operação.
                        </p>
                        <p>
                            Oferecemos a possibilidade de um projeto piloto GRATUITO! Basta entrar em contato com nosso
                            time.
                        </p>
                    </div>
                </div>

                <div className={classes.container_footer}>
                    <div className={classes.container_child}>
                        <div className={classes.sub_child}>
                            <img src={MercadoIcone} alt="mercado-icone" />
                            <p>Nossa solução VALID reduz até 36% das perdas por validade. </p>
                        </div>
                        <ButtonConheca title="Conheça" imgUrl={CalendarIcon} UrlNavigation="/valid" />
                    </div>
                    <div className={classes.container_child}>
                        <div className={classes.sub_child}>
                            <img src={FarmaciaIcone} alt="farmacia-icone" />
                            <p>
                                O ROTA, nosso sistema para inventários rotativos que aumenta a acuracidade de estoque em
                                até 99%.
                            </p>
                        </div>
                        <ButtonConheca title="Conheça" imgUrl={SetasIcon} UrlNavigation="/rota" />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <div className={classes.headline}>
                <div className={classes.headline__video}>
                    <VideoSobre />
                </div>
                <div className={classes.headline__text}>
                    <h1 className={classes.headline_title}>
                        Redução de Perdas e Gestão de Estoques com Inteligência de Dados
                    </h1>
                    <h4>
                        Desenvolvemos{' '}
                        <span className={classes.destaque}>soluções totalmente integradas ao seu ERP</span>, que vão
                        desde a entrada do produto na loja até planos de ação para{' '}
                        <span className={classes.destaque}> reduzir perdas, padronizar processos</span> e aumentar a{' '}
                        <span className={classes.destaque}>eficiência da sua operação.</span>
                    </h4>

                    <a href="https://wa.me/5544997525279" target="_blank" rel="noopener noreferrer">
                        <button>
                            Entre em <span className={classes.destaque_button}> contato </span>conosco!
                        </button>
                    </a>
                </div>
            </div>
        );
    }
};

export default Headline;
