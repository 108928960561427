import React, { Fragment, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList, Legend } from 'recharts';

import classes from './Simulador.module.scss';
import Title from '../UI/Title';


const Simulador = () => {
    const [iniciouSimulacao, setIniciouSimulacao] = useState(false);
    const [finalizouSimulacao, setFinalizouSimulacao] = useState(false);
    const [confirmouFaturamento, setConfirmouFaturamento] = useState(false);
    const [resultado, setResultado] = useState([]);
    const [resultadoMobile, setResultadoMobile] = useState([]);

    const [faturamento, setFaturamento] = useState(50);
    const [numeroLoja, setNumeroLoja] = useState(0.1);
    const [prevencao, setPrevencao] = useState(false);
    const [investiu, setInvestiu] = useState(false);

    const BASE_CALCULO = 1.87

    const handleStart = () => setIniciouSimulacao(true)

    const handleFinishButton = () => {
        setFinalizouSimulacao(true)

        let porcentagemPrevencao = (prevencao === 'true') ? 0.1 : -0.1;
        let porcentagemInvestiu = (investiu === 'true') ? 0.1 : -0.1;

        let porcentagemTotal = BASE_CALCULO + numeroLoja + porcentagemPrevencao + porcentagemInvestiu

        let perdaTotal = ((faturamento * porcentagemTotal) / 100) * 1000

        let prazo_validade = parseFloat(((perdaTotal * 25) / 100).toFixed())
        let furto_externo = parseFloat(((perdaTotal * 15) / 100).toFixed())
        let avaria = parseFloat(((perdaTotal * 14) / 100).toFixed())
        let improprio = parseFloat(((perdaTotal * 12) / 100).toFixed())
        let erros_adm = parseFloat(((perdaTotal * 10) / 100).toFixed())
        let furto_interno = parseFloat(((perdaTotal * 7) / 100).toFixed())
        let fornecedores = parseFloat(((perdaTotal * 6) / 100).toFixed())
        let dano_equipamento = parseFloat(((perdaTotal * 3) / 100).toFixed())
        let outro = parseFloat(((perdaTotal * 8) / 100).toFixed())

        const resultado = [
            {
                name: 'Prazo de Validade',
                valor: prazo_validade
            },
            {
                name: 'Furto Externo',
                valor: furto_externo
            },
            {
                name: 'Avaria',
                valor: avaria
            },
            {
                name: 'Impróprio pra Consumo',
                valor: improprio
            },
            {
                name: 'Erros Administrativos',
                valor: erros_adm
            },
            {
                name: 'Furto Interno',
                valor: furto_interno
            },
            {
                name: 'Fornecedores',
                valor: fornecedores
            },
            {
                name: 'Danos em equipamentos',
                valor: dano_equipamento
            },
            {
                name: 'Outros',
                valor: outro
            },
        ]

        const resultadoMobile = [
            {
                name: 'Prazo de Validade',
                valor: prazo_validade
            },
            {
                name: 'Furto Externo',
                valor: furto_externo
            },
            {
                name: 'Avaria',
                valor: avaria
            }
        ]

        window.scrollTo(0, 0);

        setResultado(resultado)
        setResultadoMobile(resultadoMobile)
    }
    const handleBtnRedo = () => {
        setFinalizouSimulacao(false)
        setIniciouSimulacao(false)
        setConfirmouFaturamento(false)
    }

    const handleChangeFaturamento = event => {
        setFaturamento(parseInt(event.target.value))
        setConfirmouFaturamento(true)
    }
    const handleChangeNumeroLoja = event => setNumeroLoja(parseFloat(event.target.value))
    const handleChangePrevencao = event => setPrevencao(event.target.value)
    const handleChangeInvestiu = event => setInvestiu(event.target.value)

    const handleCancelButton = () => {
        setIniciouSimulacao(false)
        setFinalizouSimulacao(false)
    }

    return (
        <Fragment>
            <Title title="Simulação de Perda" />

            <div className={classes.simulador__img}>
                <div className={classes.simulador__img_content}>
                    <h2>Você conhece as suas perdas?</h2>

                    <p>Com o nosso simulador, você têm uma estimativa das principais oportunidades para se tornar mais lucrativo!</p>
                </div>
            </div>

            <div className={classes.simulador}>
                {!iniciouSimulacao && (
                    <div className={classes.simulador__start_btn}>
                        <button onClick={handleStart}>Começar</button>
                    </div>
                )}

                {(iniciouSimulacao && !finalizouSimulacao) && (
                    <div className={classes.simulador__perguntas}>
                        <div className={classes.simulador__row}>
                            <div className={classes.simulador__box}>
                                <fieldset>
                                    <legend>Qual o seu faturamento médio por mês/loja? <span>*</span></legend>

                                    <ul>
                                        <li>
                                            <label>
                                                <input type="radio" value="50" name="faturamento" onChange={handleChangeFaturamento} />Até 100 mil
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="175" name="faturamento" onChange={handleChangeFaturamento} />De 100 mil a 250 mil
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="385" name="faturamento" onChange={handleChangeFaturamento} />De 250 mil a 500 mil
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="500" name="faturamento" onChange={handleChangeFaturamento} />Mais de 500 mil
                                            </label>
                                        </li>
                                    </ul>
                                </fieldset>
                            </div>

                            <div className={classes.simulador__box}>
                                <fieldset>
                                    <legend>Quantas Lojas sua rede possui?</legend>

                                    <ul>
                                        <li>
                                            <label>
                                                <input type="radio" value="0.1" name="numeroLoja" onChange={handleChangeNumeroLoja} />1 a 3 Lojas
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="0.2" name="numeroLoja" onChange={handleChangeNumeroLoja} />3 a 6 Lojas
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="0.3" name="numeroLoja" onChange={handleChangeNumeroLoja} />6 a 10 Lojas
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="0" name="numeroLoja" onChange={handleChangeNumeroLoja} />10 a 15 Lojas
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="0" name="numeroLoja" onChange={handleChangeNumeroLoja} />Mais de 15 Lojas
                                            </label>
                                        </li>
                                    </ul>
                                </fieldset>
                            </div>

                            <div className={classes.simulador__box}>
                                <fieldset>
                                    <legend>Qual o formato da loja?</legend>

                                    <ul>
                                        <li>
                                            <label>
                                                <input type="radio" value="Loja de Conv" name="tipoLoja" />Loja de Conveniência
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="Loja de Vizinhança" name="tipoLoja" />Loja de Vizinhança
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="Supermercado Convencional" name="tipoLoja" />Supermercado Convencional
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="Hipermercado" name="tipoLoja" />Hipermercado
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" value="Atacarejo/Atacado" name="tipoLoja" />Atacarejo/Atacado
                                            </label>
                                        </li>
                                    </ul>
                                </fieldset>
                            </div>
                        </div>

                        <div className={classes.simulador__box}>
                            <fieldset>
                                <legend>Você possui responsáveis de prevenção de perdas em Loja?</legend>

                                <ul>
                                    <li>
                                        <label>
                                            <input type="radio" value="true" name="prevencao" onChange={handleChangePrevencao} />Sim
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="radio" value="false" name="prevencao" onChange={handleChangePrevencao} />Não
                                        </label>
                                    </li>
                                </ul>
                            </fieldset>
                        </div>

                        <div className={classes.simulador__box}>
                            <fieldset>
                                <legend>Você investe/investiu em tecnologia para prevenção de perdas no último ano?</legend>

                                <ul>
                                    <li>
                                        <label>
                                            <input type="radio" value="true" name="investiu" onChange={handleChangeInvestiu} />Sim
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="radio" value="false" name="investiu" onChange={handleChangeInvestiu} />Não
                                        </label>
                                    </li>
                                </ul>
                            </fieldset>
                        </div>

                        {!finalizouSimulacao && (
                            <div className={classes.btns_acao}>
                                <button onClick={handleCancelButton}>Cancelar</button>

                                <button onClick={handleFinishButton} disabled={!confirmouFaturamento}>Simular</button>
                            </div>
                        )}
                    </div>
                )}

                {finalizouSimulacao && (
                    <div className={classes.resultados}>
                        <div className={classes.resultados__desktop}>
                            <ResponsiveContainer width="100%" height="100%" minWidth={300}>
                                <BarChart
                                    width={300}
                                    height={300}
                                    data={resultado}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 30,
                                        bottom: 50,
                                    }}
                                >
                                    <XAxis
                                        dataKey="name"
                                        textAnchor="end"
                                        tick={{ stroke: '#0f1628' }}
                                        height={70}
                                        minTickGap={1}
                                        interval={0}
                                        angle={-20}
                                        tickSize={10}
                                        tickLine={false}
                                    />
                                    <YAxis />
                                    <Legend verticalAlign="top" height={36} payload={[{ value: 'Distribuição das Perdas por Loja/mês*', type: 'line' }]} />
                                    <Tooltip formatter={(value, name, props) => `R$ ${value}`} />
                                    <Bar name={'Valor'} dataKey="valor" fill="#56bb8f">
                                        <LabelList dataKey="valor" formatter={(value, name, props) => `R$ ${value}`} position="top" />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <div className={classes.resultados__mobile}>
                            <ResponsiveContainer width="100%" height="100%" minWidth={260}>
                                <BarChart
                                    width={260}
                                    height={300}
                                    data={resultadoMobile}
                                    layout={'vertical'}
                                    margin={{
                                        top: 30,
                                        right: 50,
                                        left: 20,
                                        bottom: 10,
                                    }}
                                >
                                    <XAxis type="number" />
                                    <YAxis padding={{ bottom: 10 }} hide="true" type="category" fontSize={12} dataKey="name" />
                                    <Legend
                                        formatter={(value, entry, index) => <span className={classes.resultados__legend}>{value}</span>}
                                        verticalAlign="top"
                                        height={50}
                                        payload={[{ value: 'Top 3 distribuição das perdas por Loja/mês*', type: 'line' }]}
                                    />
                                    <Tooltip formatter={(value, name, props) => `R$ ${value}`} />
                                    <Bar dataKey="valor" fill="#56bb8f">
                                        <LabelList dataKey="name" fontSize={12} fill="#000" />
                                        <LabelList dataKey="valor" fontSize={12} formatter={(value, name, props) => `R$ ${value}`} position="right" />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <div className={classes.planos}>
                            <h3>Planos de Ação para o top 3 perdas</h3>

                            <div className={classes.planos__box}>
                                <div>
                                    <h4>Prazo de Validade</h4>

                                    <ul>
                                        <li>Implantar processos e controles de validade na loja;</li>
                                        <li>Aplicar desconto em produtos próximos ao vencimento;</li>
                                        <li>Utilizar softwares de gestão de validade;</li>
                                    </ul>
                                </div>

                                <div>
                                    <h4>Furto Externo</h4>

                                    <ul>
                                        <li>Adote tecnologias (CFTV, etiquetas antifurtos);</li>
                                        <li>Treinamento da Equipe sobre Prevenção de Furtos e sobre o processo de abordagem;</li>
                                        <li>Confinamento de produtos alto risco/exposição em área controlada;</li>
                                    </ul>
                                </div>


                                <div>
                                    <h4>Avaria</h4>

                                    <ul>
                                        <li>Treinamento da Equipe sobre manuseio dos produtos;</li>
                                        <li>Defina metas, controles e planos de ação para reduzir as avarias;</li>
                                        <li>Fiscalize a qualidade dos fornecedores;</li>
                                    </ul>
                                </div>

                                <p>* Os valores apresentados são dados aproximados, calculados com base nas informações inseridas e utilizando o benchmark da pesquisa de eficiência operacional da ABRAS. A sua Rede pode ter resultados reais um pouco divergentes, de acordo com os indicadores das suas lojas.</p>
                            </div>

                        </div>

                        <div className={classes.simulador__start_btn}>
                            <button onClick={handleBtnRedo}>Refazer</button>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default Simulador;