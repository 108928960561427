import React, { useState } from 'react';

import classes from './Tecnologias.module.scss';
import logo_aws from '../../assets/images/tecnologia/aws.svg';
import logo_bluesoft from '../../assets/images/tecnologia/bluesoft.svg';
import logo_cloudflare from '../../assets/images/tecnologia/cloudflare.svg';
import logo_consinco from '../../assets/images/tecnologia/consinco.svg';
import logo_knime from '../../assets/images/tecnologia/knime.svg';
import logo_linx from '../../assets/images/tecnologia/linx.svg';
import logo_rpinfo from '../../assets/images/tecnologia/rpinfo.svg';
import logo_sap from '../../assets/images/tecnologia/sap.svg';
import logo_totvs from '../../assets/images/tecnologia/totvs.svg';
import logo_sg_sistemas from '../../assets/images/tecnologia/sg-logo.png';

const Tecnologias = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return <></>;
    } else {
        return (
            <section className={classes.tecno}>
                <div className={classes.titulo}>
                    <h3>Tecnologias</h3>
                </div>
                <div className={classes.tecno__logos}>
                    <img src={logo_aws} alt="logo_aws" />
                    <img src={logo_bluesoft} alt="logo_bluesoft" />
                    <img src={logo_sg_sistemas} alt="logo_sg_sistemas" />
                    <img src={logo_consinco} alt="logo_consinco" />
                    <img src={logo_knime} alt="logo_knime" />
                    <img src={logo_linx} alt="logo_linx" />
                    <img src={logo_rpinfo} alt="logo_rpinfo" />
                    <img src={logo_sap} alt="logo_sap" />
                    <img src={logo_totvs} alt="logo_totvs" />
                </div>
            </section>
        );
    }
};

export default Tecnologias;
