import React from 'react';
import classes from './style.module.scss';

const HeadlineSection01 = () => {
    return (
        <div className={classes.container}>
            <div className={classes.container_textos}>
                <h2 className={classes.titulo}>Inclua inteligência de dados na sua gestão de perdas e estoque</h2>
                <p className={classes.texto}>
                    Aumente a eficiência da sua operação com as nossas soluções totalmente integradas ao seu ERP.
                </p>
                <div className={classes.ctn_btn}>
                    <a href="https://wa.me/5544997525279" target="_blank" className={classes.btn_contato}>
                        FALE CONOSCO
                    </a>
                </div>
            </div>
        </div>
    );
};

export default HeadlineSection01;
