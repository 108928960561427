import React, { useState } from 'react';
import classes from './Servicos.module.scss';
import ServicoItem from '../util/ServicoItem';

const Servicos = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (isMobilePage) {
        return (
            <section id="servicos" className={classes.servicos}>
                <div className={classes.titulo}>
                    <p>
                        Conheça os <span className={classes.destaque_titulo}>Sistemas</span> da Minus
                    </p>
                </div>

                <div className={classes.servicos__items}>
                    <ServicoItem
                        sistemas="/Valid"
                        title="Valid"
                        description="Nosso sistema de validades que reduz até 36% da perda por vencimento."
                        button="Saiba mais!"
                    />
                    <ServicoItem
                        sistemas="/Rota"
                        title="ROTA"
                        description="Nosso sistema de inventário rotativo que aumenta a acuracidade do estoque para até 99%."
                        button=" Saiba mais!"
                    />
                </div>
            </section>
        );
    }
};

export default Servicos;
