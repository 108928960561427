import React from 'react';
import classes from './BotaoInscrever.module.scss';

const BotaoInscrever = () => {

    return (
        <div className={classes.container_button}> 
            <button className={classes.botao} ><a href='https://wa.me/5544997525279' target='_blank' rel="noopener noreferrer" className={classes.ancora}><strong>Entre em<span className={classes.destaque}> contato</span> conosco!</strong></a></button>
        </div>
    );
}

export default BotaoInscrever;