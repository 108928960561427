import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Parser from 'html-react-parser';

import API from '../../api';

import linkdinIcon from '../../assets/icons/linkedin_autor.svg';
import classes from './VideoDetalhado.module.scss';
import Inscrever from '../../components/blog/Inscrever';

const baseImg = process.env.REACT_APP_BASE_IMAGE;

const VideoDetalhado = () => {
    const [videos, setVideos] = useState([]);
    const [videoDetalhe, setVideoDetalhe] = useState([]);
    const [propagandas, setPropagandas] = useState([]);
    const params = useParams();

    const { videoSlug } = params;

    useEffect(() => {
        API.get(`blog/videos`).then((response) => setVideos(response.data));
        API.get(`blog/propagandas`).then((response) => setPropagandas(response.data));
        API.get(`blog/videos/${videoSlug}`).then((response) => {
            response.data.video_frame = Parser(response.data.video_frame);
            setVideoDetalhe(response.data);
            API.patch(`blog/videos/visualizacoes`, { id: response.data.id });
        });
    }, [videoSlug]);

    const classeNoticia = propagandas.length > 0 ? '' : classes.noticia_hide;

    return (
        <main className={classes.main}>
            <div className={classes.wrapper + ' ' + classes.noticia + ' ' + classeNoticia}>
                <section className={classes.noticia__conteudo}>
                    <h1>{videoDetalhe.titulo}</h1>

                    <div className={classes.noticia__video}>{videoDetalhe.video_frame}</div>

                    <div className={classes.autor}>
                        <img
                            src={`${baseImg}/usuarios/${videoDetalhe.url_perfil}`}
                            className={classes.autor__img}
                            alt="imagem do autor"
                        />

                        <div>
                            <h4>{videoDetalhe.nome}</h4>
                            <p>{videoDetalhe.biografia}</p>
                            <Link to={videoDetalhe.linkedin} target="_blank">
                                <img src={linkdinIcon} className={classes.autor__icon} alt="linkedin icon" />
                            </Link>
                        </div>
                    </div>
                </section>

                {propagandas && (
                    <aside>
                        {propagandas.map((item) => (
                            <Link to={item.url} key={item.id} target="_blank" rel="noopener noreferrer">
                                <img src={`${baseImg}/propagandas/${item.filename}`} alt={item.titulo} />
                            </Link>
                        ))}
                    </aside>
                )}
            </div>

            <Inscrever />

            <section className={classes.wrapper + ' ' + classes.relacionados}>
                <h2>Veja Também</h2>

                <div className={classes.relacionados__card}>
                    {videos.map((video) => (
                        <Link to={`/blog/video/${video.url_slug}`} key={video.id}>
                            <img src={`${baseImg}/videos/${video.imagem_capa}`} alt={video.titulo} />
                            <h4>{video.titulo}</h4>
                        </Link>
                    ))}
                </div>
            </section>
        </main>
    );
};

export default VideoDetalhado;
