import { useState } from 'react';
import HomePageValid from './BuildValid/BuildValid';
import SubscribeBlog from './SubscribeBlog/SubscribeBlog';
import SobreValid from './SobreValid/SobreValid';
import ReducaoPerdas from './SobreValid/SectionPerdas/ReducaoPerdas';
import Leia from './SobreValid/SectionLeia/Leia';
import SectionBlog from './FooterBlog/SectionBlog';

const Valid = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return (
            <div>
                <HomePageValid />
                <ReducaoPerdas />
            </div>
        );
    } else {
        return (
            <div>
                <HomePageValid />
                <SubscribeBlog />
                <SobreValid />
                <ReducaoPerdas />
                <Leia />
                <SectionBlog />
            </div>
        );
    }
};

export default Valid;
