import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import plusIcon from '../../../assets/icons/add.svg';
import classes from './ListaPostagem.module.scss';
import Title from '../../../components/admin/UI/Title.jsx';
import Post from './Post.jsx';

const ListaPostagem = () => {
    const [todasPostagens, setTodasPostagens] = useState([]);
    const [postagens, setPostagens] = useState([]);
    const [postagensArquivado, setPostagensArquivado] = useState([]);
    const [mostraArquivados, setMostraArquivados] = useState(false);
    const navigate = useNavigate();

    const { token } = JSON.parse(sessionStorage.getItem('usuario'));

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const handleNewPost = () => navigate('/admin/postagens/create');

    useEffect(() => {
        API.get(`admin/blog`, config).then((response) => {
            let posts = response.data.filter((item) => {
                return item.arquivado === 0;
            });

            let postsArquivados = response.data.filter((item) => {
                return item.arquivado === 1;
            });

            setPostagens(posts);
            setTodasPostagens(posts);
            setPostagensArquivado(postsArquivados);
        });
    }, []);

    const arquivaPostHandler = (id) => {
        API.patch(`admin/blog/arquiva`, { id }, config).then((response) => {
            const listaFiltrada = postagens.filter((item) => {
                return item.id !== id;
            });
            const postArquivado = postagens.filter((item) => {
                return item.id === id;
            });

            setPostagens(listaFiltrada);
            setTodasPostagens(listaFiltrada);

            setPostagensArquivado((prevLista) => {
                return [...postArquivado, ...prevLista];
            });
        });
    };

    const recuperaPostHandler = (id) => {
        API.patch(`admin/blog/arquiva`, { id }, config).then((response) => {
            const listaFiltrada = postagensArquivado.filter((item) => {
                return item.id !== id;
            });
            const postRecuperado = postagensArquivado.filter((item) => {
                return item.id === id;
            });

            setPostagensArquivado(listaFiltrada);

            setPostagens((prevLista) => {
                return [...postRecuperado, ...prevLista];
            });

            setTodasPostagens((prevLista) => {
                return [...postRecuperado, ...prevLista];
            });
        });
    };

    const handlerFiltraTitulo = (event) => {
        const filtro = event.target.value;

        let filtrados = todasPostagens.filter((element) => {
            return element.titulo.toLowerCase().includes(filtro.toLowerCase());
        });

        setPostagens(filtrados);
    };

    const toggleArquivadosHandler = () => {
        setMostraArquivados(!mostraArquivados);
    };

    return (
        <div className={classes.postagens}>
            <div className={classes.postagens__header}>
                <Title title="Postagens" />

                <button onClick={handleNewPost}>
                    <img src={plusIcon} alt="plus icon" /> Adicionar
                </button>
            </div>

            <div className={classes.postagens__topo}>
                <div className={classes.buscador}>
                    <input
                        type="search"
                        name="search"
                        id="search"
                        onChange={handlerFiltraTitulo}
                        placeholder="Buscar Título"
                    />
                </div>

                {!mostraArquivados && (
                    <button className={classes.postagens__arquivados} onClick={toggleArquivadosHandler}>
                        Ver Arquivados
                    </button>
                )}

                {mostraArquivados && (
                    <button className={classes.postagens__nao_arquivados} onClick={toggleArquivadosHandler}>
                        Ver Posts
                    </button>
                )}
            </div>

            <ul className={classes.postagens__itens}>
                {mostraArquivados &&
                    postagensArquivado.map((item) => (
                        <Post
                            key={item.id}
                            {...item}
                            onArquivaPost={recuperaPostHandler}
                            arquivado={mostraArquivados}
                        />
                    ))}

                {!mostraArquivados &&
                    postagens.map((item) => (
                        <Post key={item.id} {...item} onArquivaPost={arquivaPostHandler} arquivado={mostraArquivados} />
                    ))}
            </ul>
        </div>
    );
};

export default ListaPostagem;
