import React, { useState, useRef } from 'react';
import classes from '../Valid.module.scss';
import VideoSobre from '../MainVideo';
import FormularioValid from '../Formulario/FormularioValid';
import HeadlineComponent from '../../layout/HeadlineComponent';
import animationValid from '../../../assets/images/desktop/animationValid.png';
import ValidNaPratica from '../ValidNaPratica/index';

const HomePageValid = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;
    const formRef = useRef(null);

    const scrollToForm = () => {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    if (!isMobilePage) {
        return (
            <>
                <HeadlineComponent
                    title="Sistema Valid"
                    subtitle="Nossa solução de Gestão de Validades"
                    description="Chega de processos manuais e despadronizados na sua operação."
                    image={animationValid}
                    ableBackground
                    ableButton
                />

                <div className={classes.headline__desktop}>
                    <div className={classes.headline__desktop__text__desktop}>
                        <h1 id={classes.titulo}>Integre inteligência de dados e estatística no seu processo</h1>
                        <h4 className={classes.conteudo}>
                            Todo varejista já enfrentou ou enfrenta problemas relacionados a gestão de validades.
                        </h4>
                        <h4 className={classes.conteudo}>
                            A realidade da Gestão de Validades na maioria das lojas consiste em processos manuais e
                            despadronizados, pouca informação para tomada de decisão e ineficiência no controle em loja.
                        </h4>

                        <h4 className={classes.conteudo}>
                            Foi pensando nesses desafios que desenvolvemos o VALID, sistema que utiliza{' '}
                            <strong>inteligência de dados e estatística</strong> para auxiliar a identificar quais
                            produtos são os <strong>maiores riscos de perda </strong>dentro de cada uma das lojas da sua
                            rede
                        </h4>

                        <h4 className={classes.conteudo}>
                            Para isso, utilizamos dados que você tem disponível no seu próprio ERP, como: histórico de
                            perdas, histórico de vendas, estoque atual, entre outras informações.
                        </h4>
                        <h4 className={classes.conteudo}>
                            Quer saber mais sobre a nossa solução? Solicite uma apresentação comercial para saber como
                            podemos lhe ajudar! Basta preencher o formulário desta página.
                        </h4>
                    </div>

                    <div className={classes.formulario}>
                        <FormularioValid ref={formRef} />
                    </div>
                </div>
                <div>
                    <ValidNaPratica onScrollToForm={scrollToForm} />
                </div>
            </>
        );
    } else {
        return (
            <div className={classes.headline}>
                <div className={classes.headline__text}>
                    <h1>Sistema Valid</h1>
                    <h4>
                        Todo varejista{' '}
                        <span className={classes.destaque}>
                            já enfrentou ou enfrenta problemas relacionados a gestão de validades!
                        </span>
                    </h4>
                    <h4>
                        A realidade da Gestão de Validades na maioria das lojas consiste em: processos manuais e
                        despadronizados, pouca informação para tomada de decisão e ineficiência no controle em loja.
                    </h4>
                    <h4>
                        Foi pensando nesses desafios que{' '}
                        <span className={classes.destaque}>desenvolvemos o VALID,</span> sistema que utiliza
                        <span className={classes.destaque}>inteligência de dados e estatística</span> para auxiliar a
                        identificar quais produtos são os maiores{' '}
                        <span className={classes.destaque}>riscos de perda</span> dentro de cada uma das lojas da sua
                        rede, utilizando dados que você tem disponível no seu ERP, como por exemplo: histórico de
                        perdas, histórico de vendas, estoque atual, entre outras informações.
                    </h4>
                </div>
                <h5>
                    <VideoSobre />
                </h5>
                <div className={classes.texto}>
                    <p>
                        Um dos nossos<span className={classes.destaque}> consultores </span> pode{' '}
                        <span className={classes.destaque}>te ajudar,</span> entre em{' '}
                        <span className={classes.destaque}>contato</span> para conhecer mais sobre o{' '}
                        <span className={classes.destaque}>VALID!</span>
                    </p>
                </div>
                <h6>
                    <FormularioValid />
                </h6>
            </div>
        );
    }
};

export default HomePageValid;
