import React from 'react';
import HeaderComponent from '../components/layout/HeadlineComponent';
import classes from './SobrePage.module.scss';
import CarrouselTecnologia from '../components/layout/CarrouselTecnologia/index';

const SobrePage = () => {
    return (
        <div className={classes.container}>
            <HeaderComponent
                title="Somos a MINUS"
                secondary="Atuando há 5 anos na Prevenção de Perdas e Gestão de Validades em operações do varejo"
                description="Conheça mais sobre quem está fazendo tudo isto acontecer"
                videoUrl="https://www.youtube.com/embed/9FIwKO4A0EU?modestbranding=1&rel=0&vq=hd1080"
            />
            <section className={classes.container_textos}>
                <h2>DO PARANÁ PARA TODO O BRASIL</h2>
                <p>
                    Somos uma empresa de tecnologia para o varejo com sede em Maringá - PR, com soluções focadas na{' '}
                    <strong>gestão de validades</strong> e na <strong>gestão de estoques</strong>. Trabalhamos
                    incessantemente para resolver alguns dos problemas mais complexos do varejo brasileiro.
                </p>
                <p>
                    Nossas soluções já estão presentes em 258 cidades por todo o Brasil, e temos como propósito levar
                    nossas soluções ainda mais longe em nosso país.
                </p>
                <p>
                    Nossos sistemas <strong>substituem controles manuais</strong> e em planilhas, comumente adotados no
                    varejo, por <strong>tecnologia preditiva de ponta,</strong> que utiliza estatística avançada e
                    soluções mobile para apontar produtos em risco de vencimento e quais ações podem ser tomadas para
                    evitar o desperdício na operação. Tudo isso desenvolvido por um{' '}
                    <strong>time de especialistas em varejo,</strong> preocupados de fato com o desperdício no país.{' '}
                </p>
                <p>
                    Nosso objetivo é adequar nossas soluções ao processo dos nossos clientes, trazendo melhorias que
                    mudem sua rotina para melhor e tragam resultados representativos.
                </p>
            </section>
            <div>
                <CarrouselTecnologia />
            </div>
        </div>
    );
};

export default SobrePage;
