import React from 'react';
import minusLogo from '../../../assets/images/minus.svg';
import classes from './style.module.css';
import { MdOutlineDone } from 'react-icons/md';

const index = () => {
    return (
        <div className={classes.container}>
            <h2>Deu tudo certo!</h2>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <p>Seus dados foram enviados! agradeçemos seu contato</p>
                <MdOutlineDone color="#48B485" size={55} />
            </div>
        </div>
    );
};

export default index;
