import React from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './Usuario.module.scss';

const Usuario = (props) => {
    const navigate = useNavigate();

    const editaUsuarioHandler = (user) => {
        const usuario = {
            id: user.id,
            nome: user.nome,
        };

        navigate('/admin/usuarios/edit-senha', { state: { usuario } });
    };

    const editaPermissaoUsuarioHandler = (user) => {
        const usuario = {
            id: user.id,
            nome: user.nome,
            is_admin: user.is_admin,
        };

        navigate('/admin/usuarios/edit-permissao', { state: { usuario } });
    };

    const confirmaDelete = (user_id) => props.modalConfirmacao(user_id);

    return (
        <li className={classes.usuario}>
            <div className={classes.usuario__info}>
                <div className={classes.usuario__detalhes}>
                    <strong>Nome:</strong>
                    <span>{props.nome}</span>
                </div>

                <div className={classes.usuario__detalhes}>
                    <strong>Função:</strong>
                    <span>{props.funcao}</span>
                </div>

                <div className={classes.usuario__detalhes}>
                    <strong>Email:</strong>
                    <span>{props.email}</span>
                </div>

                <div className={classes.usuario__detalhes}>
                    <strong>Possui permissão de Admin:</strong>
                    <span>{props.is_admin === 0 ? 'Não' : 'Sim'}</span>
                </div>
            </div>

            <div className={classes.usuario__acoes}>
                <button onClick={() => editaUsuarioHandler(props)}>Editar Senha</button>
                <button onClick={() => editaPermissaoUsuarioHandler(props)}>Editar Permissões</button>
                <button onClick={() => confirmaDelete(props.id)}>Excluir</button>
            </div>
        </li>
    );
};

export default Usuario;
