import React, { useState } from 'react';

import classes from './ReducaoPerdas.module.scss';
import graficos from '../../../../assets/images/perdas_grafico.png';
import perdas from '../../../../assets/images/alvo_perdas.png';
import Titulo from './Titulo';
import grafico_desktop from '../../../../assets/images/desktop/recessao.png';
import relogio_desktop from '../../../../assets/images/desktop/relogio.png';

const ReducaoPerdas = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return (
            <div className={classes.section__desktop}>
                <div>
                    <div className={classes.container__desktop}>
                        <div className={classes.cards__desktop}>
                            <img src={grafico_desktop} />
                            <p>36,04%</p>
                            <h2>de redução de perdas por vencimento</h2>
                        </div>

                        <div className={classes.cards__desktop}>
                            <img src={relogio_desktop} />
                            <p>40,66%</p>
                            <h2>de redução no tempo total do processo</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={classes.section}>
                <div>
                    <Titulo />

                    <div className={classes.container}>
                        <div className={classes.cards}>
                            <h2>Redução de Perdas por Vencimento</h2>
                            <img src={graficos} />
                            <p>36,04%</p>
                        </div>

                        <div className={classes.cards}>
                            <h2>Redução no tempo total do processo</h2>
                            <img src={perdas} />
                            <p>40,66%</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ReducaoPerdas;
