import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import API from '../../api';

import classes from './Videos.module.scss';
import Title from '../../components/UI/Title';

const baseImg = process.env.REACT_APP_BASE_IMAGE;

const Videos = () => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        API.get(`blog/todos-videos`).then((response) => setVideos(response.data));
    }, []);

    return (
        <main className={classes.main}>
            <Title title="Videos" />

            <section className={classes.wrapper + ' ' + classes.videos}>
                <div className={classes.videos__posts}>
                    {videos.map((video) => (
                        <Link to={`/blog/video/${video.url_slug}`} key={video.id}>
                            <img src={`${baseImg}/videos/${video.imagem_capa}`} alt={video.titulo} />
                            <h4>{video.titulo}</h4>
                        </Link>
                    ))}
                </div>
            </section>
        </main>
    );
};

export default Videos;
