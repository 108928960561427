import React from 'react';
import Card from './Card';
import classes from './Card.module.scss';
import valid_um from '../../../assets/images/valid_um.png';
import valid_dois from  '../../../assets/images/valid_dois.png';
import valid_tres from '../../../assets/images/valid_tres.png';
import valid_quatro from '../../../assets/images/valid_quatro.png';
import style from './SobreValid.module.scss';


const SobreValid = () => {

    return (
        <div>
            <div id='sobrevalid' className={style.sobrevalid}>
                <h1>O Sistema <span className={style.titulo}>VALID</span> e o <span className={style.titulo}>Ganho de Eficiência</span></h1>
            </div>  
            <div className={style.cards}>
                 <Card
                    image={valid_um}
                    altText="Texto alternativo da imagem"
                    title="Tecnologia Mobile"
                    description="Com aplicativo mobile com operação online e offline, possibilidade de múltiplos usuários, funcional em celulares e coletores, as informações são acessadas e obtidas de maneira fácil e rápida!"/>                 
                <Card
                    image={valid_dois}
                    altText="Texto alternativo da imagem"
                    title="Atue estrategicamente na Prevenção de Perdas"
                    description="Eleve a Prevenção de Perdas para o nível estratégico da sua Rede! Com o VALID é possível trabalhar os dados do seu negócio de maneira preventiva visando o aumento da eficiência e eficácia da operação de Prevenção de Perdas"/>
                <Card
                    image={valid_tres}
                    altText="Texto alternativo da imagem"
                    title="Dashboards Personalizados"
                    description="Dashboards com informações de previsibilidade de vencimento, quantidade de SKUs críticos, monitoramento e acompanhamento de execução e eficiência das operações de loja!"/>
                <Card
                    image={valid_quatro}
                    altText="Texto alternativo da imagem"
                    title="Otimize o Processo de Gestão de Validade"
                    description="Com o Sistema VALID a troca de informações entre loja e administração fica muito mais fácil, graças ao nosso painel de acompanhamento com informações de todas as lojas!"/>  
            </div>
        </div>
    );
}

export default SobreValid;