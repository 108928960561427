import React from 'react';
import Card from './Card';
import smartphone from '../../../assets/images/desktop/smartphone.png';
import solucao from '../../../assets/images/desktop/solucao-de-problemas.png';
import analise from '../../..//assets/images/desktop/analise.png';
import navegador from '../../../assets/images/desktop/navegador-da-web.png';
import classes from './style.module.scss';

const index = ({ onScrollToForm }) => {
    return (
        <div className={classes.container}>
            <div>
                <h3 className={classes.titulo}>O VALID NA PRÁTICA</h3>
                <p className={classes.paragrafo}>
                    O VALID identifica e estratifica alguns produtos que necessitam atenção e, a partir do aplicativo em
                    funcionamento no smartphone e/ou coletor de dados, são geradas tarefas para execução pela operação
                    de loja. Com a execução da tarefa, todas as informações são coletadas e disponibilizadas para
                    gerência de loja e áreas administrativas da rede.
                </p>
                <p className={classes.paragrafo}>
                    Dessa forma, o processo de "arrastão" torna-se desnecessário, reduzindo o tempo e colaboradores
                    necessários. Com base nesses dados, é possível traçar planos de ação afim de evitar a quebra em
                    meses seguintes e realizar um planejamento de médio e longo prazo para que essas quebras não
                    retornem.
                </p>
            </div>
            <div className={classes.cards}>
                <Card
                    image={smartphone}
                    title="Conte com a tecnologia mobile"
                    content="Nossa aplicação possui operação online e offline, múltiplos usuários, disponível para celulares e coletores."
                />
                <Card
                    image={solucao}
                    title="Atue estrategicamente"
                    content="Com o VALID você trabalha com dados focando em prevenção e aumento de eficiência da sua operação."
                />
                <Card
                    image={analise}
                    title="Analise com propriedade"
                    content="Acesso a painéis de previsibilidade de vencimento, SKU’s críticos, monitoramento da execução e eficiência das operações."
                />
                <Card
                    image={navegador}
                    title="Otimize seu processo"
                    content="Simplificação da troca de informações entre loja e administração com o painel de acompanhamento de lojas."
                />
            </div>

            <button onClick={onScrollToForm} className={classes.btn_contato}>
                QUERO FALAR COM CONSULTOR
            </button>
        </div>
    );
};

export default index;
