import React from 'react';

import classes from './CardImageRight.module.scss';

const CardImageRight = (props) => {
    return (
        <div className={classes.card}>
                  <div className={classes.elementos}>
                      <div className={classes.text}>
                          <h2>{props.title}</h2></div>

                        <li className={classes.lista}>
                            <p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class={classes.icon}>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                      </svg>
                            {props.description1}</p>
                        </li>

                        <li className={classes.lista}>

                            <p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class={classes.icon}>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                      </svg>
                              {props.description2}</p>
                        </li>
                        <li className={classes.lista}>
                            <p>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class={classes.icone}>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                      </svg>{props.description3}</p>
                        </li>
                        <img  className={classes.media} src={props.image} alt={props.altText} />        
                    </div>
        </div>
    );
}

export default CardImageRight;
