import React, { useState } from 'react';

import grafico from '../../../assets/images/svg_grafico.svg';
import lojas from '../../../assets/images/png_lojas.png';
import pessoa from '../../../assets/images/png_pessoas.png';
import like from '../../../assets/images/svg_like.svg';
import StoreCounter from '../CountLojas/index';

import classes from './DadosEstatistica.scss';

const Clientes = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    if (!isMobilePage) {
        return (
            <section id="estatistica__desktop" className={classes.estatistica__desktop}>
                <h2 id="titulo_desktop">Nossos Números</h2>
                <div id="elementos__desktop" className={classes.elementos__desktop}>
                    <StoreCounter />
                </div>
            </section>
        );
    } else {
        return (
            <section id="estatistica" className={classes.estatistica}>
                <h2>Nossos Resultados</h2>

                <div id="elementos" className={classes.elementos}>
                    <div id="grafico" className={classes.logo_cliente}>
                        <h3>
                            Redução de Perdas<br></br> por Vencimento
                        </h3>
                        <img src={grafico} alt="graficos" />
                        <p>36,04%</p>
                    </div>

                    <div id="lojas" className={classes.logo_cliente}>
                        <h3>
                            Número <br></br>
                            de Lojas
                        </h3>
                        <img src={lojas} alt="lojas" />
                        <p>+782</p>
                    </div>

                    <div id="pessoas" className={classes.logo_cliente}>
                        <h3>
                            Números <br></br>de Usuários
                        </h3>
                        <img src={pessoa} alt="pessoas" />
                        <p>+850</p>
                    </div>

                    <div id="like" className={classes.logo_cliente}>
                        <h3>
                            Satisfação <br></br>dos clientes
                        </h3>
                        <img src={like} alt="like" />
                        <p>99%</p>
                    </div>
                </div>
            </section>
        );
    }
};

export default Clientes;
